import { FunctionN } from 'fp-ts/lib/function';
import { record } from 'fp-ts/lib/Record';
import { Functor1 } from 'fp-ts/lib/Functor';
import { Do } from 'fp-ts-contrib/lib/Do';
import { either } from 'fp-ts/lib/Either';
import * as T from 'io-ts';
import { LOCATION, PRODUCT, TIME, DEFAULT_DIMENSIONS } from 'utils/domain/constants';

export type Space<A> = { location: A, product: A, time: A, [k: string]: A }

export type RequiredDimension = typeof LOCATION | typeof PRODUCT | typeof TIME;

export function isRequiredDimension(s: string): s is RequiredDimension {
  return [...DEFAULT_DIMENSIONS].some((dim) => dim === s);
}

function createSpaceEncode<Of extends T.Mixed>(of: Of): T.Encode<Space<T.TypeOf<Of>>, Space<T.TypeOf<Of>>> {
  return (space) => {
    const product = of.encode(space.product);
    const location = of.encode(space.location);
    const time = of.encode(space.time);
    const all = T.record(T.string, of).encode(space);
    return {
      product,
      location,
      time,
      ...all
    };
  };
}

function createSpaceIs<Of extends T.Mixed>(of: Of): T.Is<Space<T.TypeOf<Of>>> {
  return (u): u is Space<T.TypeOf<Of>> => {
    if (T.record(T.string, of).is(u)) {
      return of.is(u['location']) && of.is(u['product']) && of.is(u['time']);
    }
    return false;
  };
}

function createSpaceValidate<Of extends T.Mixed>(of: Of): T.Validate<unknown, Space<T.TypeOf<Of>>> {
  return (i, c) => {
    const result = Do(either)
      .bind('unkr', T.UnknownRecord.validate(i, c))
      .bindL('product', ({ unkr }) => of.validate(unkr['product'], T.appendContext(c, 'product', of, unkr['product'])))
      // eslint-disable-next-line max-len
      .bindL('location', ({ unkr }) => of.validate(unkr['location'], T.appendContext(c, 'location', of, unkr['location'])))
      .bindL('time', ({ unkr }) => of.validate(unkr['time'], T.appendContext(c, 'time', of, unkr['time'])))
      .bind('all', T.record(T.string, of).validate(i, c))
      .done();

    // Explicitly map here so that we strip off the unkr which is a partially validated record
    // May prevent confusion in if structure is ever logged or viewed in debugger
    return either.map(
      result,
      ({ product, location, time, all }) => ({
        ...all,
        product,
        location,
        time
      })
    );
  };
}

export function TSpace<Type extends T.Mixed>(of: Type): T.Type<Space<T.TypeOf<Type>>> {
  class SpaceType<Of extends T.Mixed> extends T.Type<Space<T.TypeOf<Of>>> {
    constructor(private readonly of: Of, name: string) {
      super(name, createSpaceIs(of), createSpaceValidate(of), createSpaceEncode(of));
    }
  }
  return new SpaceType(of, `Space<${of.name}>`);
}



export const URI = 'Space';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type URI = typeof URI;

declare module 'fp-ts/lib/HKT' {
  interface URItoKind<A> {
    readonly [URI]: Space<A>
  }
}

export function map<A, B>(f: FunctionN<[A], B>): FunctionN<[Space<A>], Space<B>> {
  return (sa) => {
    const location = f(sa.location);
    const product = f(sa.product);
    const time = f(sa.time);
    const rest = record.map(sa, f);
    return {
      ...rest,
      location,
      product,
      time
    };
  };
}

// Space can also provide Traversable1, I just don't feel like writing the instances until I need them
export const space: Functor1<URI> = {
  URI,
  map: (fa, f) => map(f)(fa)
};
