import numeral from 'numbro';

numeral.culture('en-UK', {
  langLocaleCode: 'en-UK',
  cultureCode: 'en-UK',
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function (number) {
    var b = number % 10;
    return (~~ (number % 100 / 10) === 1) ? 'th' :
      (b === 1) ? 'st' :
        (b === 2) ? 'nd' :
          (b === 3) ? 'rd' : 'th';
  },
  currency: {
    symbol: '£',
    position: 'prefix'
  },
  defaults: {
    currencyFormat: ',4 a'
  },
  formats: {
    fourDigits: '4 a',
    fullWithTwoDecimals: '$ ,0.00',
    fullWithTwoDecimalsNoCurrency: ',0.00',
    fullWithNoDecimals: '$ ,0'
  }
});
export default numeral;
