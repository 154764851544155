import * as iots from 'io-ts';

export interface Tree<A> {
  v: A,
  children: ReadonlyArray<Tree<A>>
}

export function makeTreeTypeOf<Type extends iots.Mixed>(of: Type, name: string = 'Tree') {
  return iots.recursion<Tree<iots.OutputOf<Type>>>(name, (self) =>
    iots.type({
      v: of,
      children: iots.readonlyArray(self)
    })
  );
}

export type InlineTree<A> = A & { children: InlineTree<A>[] }

