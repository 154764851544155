import Modal from '@trendmicro/react-modal';
import * as React from 'react';
import { AuthContext } from 'services';
import {
  Grid,
  Header,
  Segment,
  Button,
  Select
} from 'semantic-ui-react';
import { AppState, AppThunkDispatch } from 'store';
import { connect } from 'react-redux';
import { getPublishVersions, publishToVersion } from 'state/scope/ScopeManagement.actions';
import { getScopeReadyData, isBusy, isReady } from 'state/scope/Scope.types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
  usePublishVersionsToDropdowns,
  useHandleKeyPress,
  useSetSelectedStringVersion,
  useGetPublishVersions,
  useHandleChangeStringVersion
} from 'utils/component/hooks/hooks';
import 'components/InitializePlan/_InitializePlan.scss';
import AnchorRadioSelect from 'components/AnchorRadioSelect/AnchorRadioSelect';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import { TopMembers } from 'services/Scope.client';
import { getScopeObject, planFromSpace } from 'components/Scopebar/ScopeUtils';

const mapStateToProps = (state: AppState) => {
  const { settings} = state;
  const labelDimenion = settings.dimensionLabelProperty;
  const readyScope = getScopeReadyData(state.scope);

  if (readyScope) {
    const anchor = getScopeObject(readyScope.mainConfig.memberTrees);
    return {
      scope: readyScope,
      publishVersions: state.scopeManagement.publishVersions,
      plans: readyScope.mainConfig.initializedPlans,
      anchor,
      labelDimenion,
      isMultiScope: readyScope.isMultiScope,
      pendingWrites: readyScope.pendingWrites > 0
    };
  }
  return {
    scope: readyScope,
    publishVersions: state.scopeManagement.publishVersions,
    plans: [],
    labelDimenion
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    getPublishVersions: (currentSubmitPlanId: PlanId) => dispatch(getPublishVersions(currentSubmitPlanId)),
    dispatchedPublishToVersion: (version: string, currentSubmitPlanId: PlanId) => {
      return dispatch(publishToVersion({ versionToPublishTo: version, applyTo: currentSubmitPlanId }));
    }
  };
};

export type SubmitPlanProps = SubmitPlanValueProps & SubmitPlanDispatchProps & SubmitPlanOwnProps;
export type SubmitPlanValueProps = ReturnType<typeof mapStateToProps>;
export type SubmitPlanDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type DispatchGetPublishVersions = SubmitPlanDispatchProps['getPublishVersions'];
export type SubmitPlanOwnProps = {
  loading: boolean,
  onSubmit: (id?: number) => void,
  onCancel: () => void,
  onOpen: () => void
}

const SubmitPlanModal = (props: SubmitPlanProps) => {
  const {
    onCancel,
    onSubmit,
    scope,
    getPublishVersions: dispatchedGetPublishedVersions,
    publishVersions,
    dispatchedPublishToVersion,
    plans,
    labelDimenion,
    anchor,
    isMultiScope,
    pendingWrites
  } = props;
  // TODO: implement default version selecting
  const buttonRef = useRef<HTMLDivElement>(null);
  const logger = useContext(AuthContext)!.serviceEnv.logging;
  const loading = !publishVersions;
  const [selectedVersion, setSelectedVersion] = useState<undefined | string>(undefined);
  const [mutationPending, setMutationPending] = useState(false);
  const [currentSubmitPlanId, setCurrentSubmitPlanId] = useState<PlanId>(plans[0].id);
  const handleChangeSubmitSelections = useCallback((newMembers: TopMembers) => {
    if (!scope) { return; }
    setCurrentSubmitPlanId(planFromSpace(scope.mainConfig.initializedPlans, newMembers).id);
  }, [scope, setCurrentSubmitPlanId]);

  useEffect(() => {
    const submitButton = buttonRef.current;
    if(submitButton){
      submitButton.focus();
    }
  },[selectedVersion]);
  const getValueFromScope = scope ? scope.currentAnchors : undefined;

  const handleSubmit = useCallback(async () => {
    if (selectedVersion && typeof selectedVersion === 'string') {
      setMutationPending(true);
      const publishReturn = await dispatchedPublishToVersion(selectedVersion, currentSubmitPlanId);
      if(publishReturn.type === publishToVersion.rejected.type){
        toast.error('An error occured submitting your version', {
          position: toast.POSITION.TOP_LEFT
        });
        setMutationPending(false);
        logger.error(`An error occured submitting the version: ${selectedVersion}`);
        return;
      }
      // this closes the modal
      if(!isMultiScope){
        onSubmit();
      }
      setMutationPending(false);
    }
  }, [selectedVersion, dispatchedPublishToVersion, currentSubmitPlanId, onSubmit, logger]);

  const handleEnterPress = useHandleKeyPress(handleSubmit);
  const versionDropdowns = usePublishVersionsToDropdowns(publishVersions);
  useSetSelectedStringVersion(versionDropdowns, setSelectedVersion, selectedVersion);
  useGetPublishVersions(dispatchedGetPublishedVersions, currentSubmitPlanId);
  const handleChangeVersion = useHandleChangeStringVersion(setSelectedVersion);

  return (
    <React.Fragment>
      <div className="initialize-plan" >
        <Grid columns={1} doubling={true} stretched={true}>
          <Grid.Column>
            <Segment>
              <Header as="h3" className="initialize-plan-header">
                {/* TODO: This text needs to be conditional.  If only one submit target exists,
                lock the dropdown and  change the "targeted version" text just say the only version name*/}
                Submit your WP to the selected Version
              </Header>
              <div>
                {getValueFromScope ?
                  <AnchorRadioSelect
                    labelDimenion={labelDimenion!}
                    anchor={anchor!}
                    onUpdateAnchorSelections={handleChangeSubmitSelections}
                  /> :
                  null
                }
              </div>
              <div className="dropdown-group">
                <Select
                  fluid={true}
                  loading={loading}
                  disabled={!scope}
                  data-qa="submit-plan-dropdown"
                  icon={<i className="chevron far fa-chevron-down icon" />}
                  options={versionDropdowns}
                  value={selectedVersion}
                  onChange={handleChangeVersion}
                />
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
      <Modal.Footer>
        <Button content="Close" onClick={onCancel} />
        <div ref={buttonRef} className="submit-button-modal" tabIndex={0} onKeyPress={handleEnterPress}>
          <Button
            content="Submit"
            className="import-version-modal-button"
            data-qa="submit-btn-submit"
            onClick={handleSubmit}
            loading={loading || mutationPending || pendingWrites}
            disabled={pendingWrites}
          />
        </div>
      </Modal.Footer>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitPlanModal);


