import { ServerScopeMember } from 'state/scope/Scope.types';
import { SeedActuals } from 'state/scope/ScopeManagement.slice';
import { SCOPETYPE_ACTUALS } from 'utils/domain/constants';
import { PlanId } from '../PlanMetadata';

export const memberToSeedActuals = (member: ServerScopeMember, applyTo: PlanId): SeedActuals => {
  return {
    applyTo,
    seedType: SCOPETYPE_ACTUALS,
    seedTime: member.id,
    name: SCOPETYPE_ACTUALS,
    planId: null
  };
};
