import { DropdownItemProps } from 'semantic-ui-react';
import { AvailableMembers, TopMembers } from '../../services/Scope.client';
import { SettingsEntry } from '../../services/Settings';
import {
  ServerScope,
  ServerScopeResponse
} from '../../state/scope/Scope.types';
import { FacetEntry } from 'state/ViewConfig/ViewConfig.types';
import { AxiosEnv, LoggingEnv, SettingsEnv } from 'services';
import { WorkingSetContext } from 'state/workingSets/workingSets.slice';
import { mapDispatchToProps } from './Scopebar.container';
import { SeedActuals, SeedPlan } from 'state/scope/ScopeManagement.slice';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import { Space } from 'space';
import { DimensionLabelSpace } from 'state/settings/settings.slice';

export interface ScopebarState {
  isModalAttached: boolean,
  loading: boolean,
  isScopeVisible: boolean,
  prevScopeId?: string,
  scopeError: boolean
}

export type SeedInfoSelections = 'copyPeriod' | 'copyVersion';

export interface SeedInfo {
  copyPeriod?: string,
  copyVersion?: PlanId | string// PlanId or time member id as string
}

export type ScopebarProps = ScopebarDispatch & ScopebarValueProps & ScopebarOwnProps;

export interface ScopebarOwnProps {
  serviceEnv: AxiosEnv & SettingsEnv & LoggingEnv
}

export interface ScopebarValueProps {
  selectedMembers: TopMembers | undefined,
  scopeId: string | undefined,
  availableMembers: AvailableMembers | undefined,
  inSeason: string | undefined,
  facet?: FacetEntry,
  scopeConfig: ServerScope | undefined,
  eopOptions: Record<number, (SeedActuals | SeedPlan)[]>,
  timeMembers?: DropdownItemProps[],
  initialized: boolean,
  settingsByKey: {
    [key: string]: SettingsEntry
  },
  viewConfig: any, // 💩
  isFetchingScope: boolean,
  hasEditableRevision: boolean,
  scopeReady: boolean,
  scopeContexts: WorkingSetContext[],
  commentsOpen: boolean,
  anyPlansUnititialized: boolean,
  pendingWrites: number,
  labelDimensions?: DimensionLabelSpace
}

export type ScopebarDispatch = ReturnType<typeof mapDispatchToProps>

// testing utils
export const openSubmitQaSelector = 'submit-copy-btn-open';
export const openCopyQaSelector = 'eop-copy-btn-open';
export const openVersionQaSelector = 'version-save-btn-open';
export const openImportQaSelector = 'version-import-btn-open';
export const openReseedQaSelector = 'reseed-reseed-btn-open';
export const openCommentsQaSelector = 'scope-comments-btn-open';
export const openScopeSelectQaSelector = 'scope-select-btn-open';
export const titledModalQaSelector = 'scopebar-option-titled-modal';

export type ScopebarModalQaAttributes = typeof openVersionQaSelector |
  typeof openImportQaSelector |
  typeof openReseedQaSelector |
  typeof openScopeSelectQaSelector;
