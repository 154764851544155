import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { isNil, flatMap, isEmpty } from 'lodash';
import { ViewConfigSlice } from 'state/ViewConfig/ViewConfig.slice';
import { ViewEntry } from 'state/ViewConfig/ViewConfig.types';

type NavLinkProps = {
  scopeId: string | undefined,
  facet: ViewConfigSlice['facet'],
  activeViews: ViewEntry[],
  pathname: string
};

const NavLinks = (props: NavLinkProps) => {
  const [currentLocation, setCurrentLocation] = useState(['', '', '']);

  useEffect(() => {
    const path = props.pathname;
    if (path.length > 0) {
      setCurrentLocation(path.split('/'));
    }
  }, [props.pathname]);
  // This can occur, for instance, if going to the logout page

  const perspective: string | undefined = currentLocation[1];
  const page: string | undefined = currentLocation[2];

  const { scopeId, facet } = props;
  const queryParams: {
    scope: string | undefined,
    facet: string | undefined
  } = {
    scope: undefined,
    facet: undefined
  };
  if (!isNil(scopeId)) {
    // note 'scopeId' is loaded into the 'scope' param, not 'scopeId'
    queryParams.scope = scopeId;
  }
  if (!isNil(facet)) {
    queryParams.facet = facet.id;
  }
  const stringifiedParams = qs.stringify(queryParams);

  return (
    <ul className="navbar-list">
      {props.activeViews.map(view => {
        const { description = '', displayName = '', id = '', views = [] as ViewEntry[] } = view;
        const subViews = flatMap(views, view => view.views as ViewEntry[]);
        let viewId = '';
        if (subViews && !isEmpty(subViews)) {
          const defaultSubView: ViewEntry =
            subViews.find(view => view.isDefault || false) || subViews[0];
          viewId = defaultSubView.id;
        }
        let href = `#/${perspective}/${id}/${viewId}`;
        href = scopeId || facet ? href += `?${stringifiedParams}` : href;

        return (
          <li className="navbar-item" key={id}>
            <a
              aria-current={!!page && page.includes(id)}
              className="navbar-link"
              data-qa={id}
              href={href}
              title={description}
            >
              {displayName}
            </a>
          </li>
        );
      })}
    </ul>
  );

};
export default NavLinks;
