import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Comment } from './Comments.types';
import { addComment, loadPlanComments, loadScopeComments } from './Comments.actions';
import dayjs from 'dayjs';
import { PlanId } from 'state/scope/codecs/PlanMetadata';

export interface CommentsState {
  commentsPlanId: PlanId | null,
  commentsAsyncState:
  // TODO move the definitions of these out
  'loadingScopeComments' |
  'loadingPlanComments' |
  'unloaded' |
  'loadedScope' |
  'loadedPlanComments' |
  'error'
}
const initCommentsState: CommentsState = {
  commentsPlanId: null,
  commentsAsyncState: 'unloaded'
};

const commentsAdapter = createEntityAdapter<Comment>({
  selectId: (comment) => comment.commentId,
  // sort by modified time
  sortComparer: (rCom, lCom) => rCom.modifiedAt.unix() - lCom.modifiedAt.unix()
});

export const commentsSlice = createSlice({
  name: 'comments',
  initialState: commentsAdapter.getInitialState(initCommentsState),
  reducers: {
    resetComments: () => commentsAdapter.getInitialState(initCommentsState) // unused
  },
  extraReducers: (builder) => {
    // #region "LoadComments" is just for the current scope WP comment loading
    builder.addCase(loadScopeComments.pending, (state) => {
      state.commentsAsyncState = 'loadingScopeComments';
      state.commentsPlanId = null;
    });
    builder.addCase(loadScopeComments.fulfilled, (state, { payload }) => {
      // TODO: add parsing and do this there
      const commentsWithData = payload.map(commentPayloadToCommentRuntime);
      commentsAdapter.setAll(state, commentsWithData);
      state.commentsAsyncState = 'loadedScope';
    });
    builder.addCase(loadScopeComments.rejected, (state, { payload }) => {
      state.commentsAsyncState = 'error';
    });
    // #endregion
    // #region "AddComments"
    builder.addCase(addComment.pending, (state) => {
      // empty on load
      state.commentsAsyncState = 'loadingScopeComments';
    });
    builder.addCase(addComment.fulfilled, (state, { payload }) => {
      // state.commentsAsyncState = 'unloaded';
      commentsAdapter.addMany(state, payload.map(commentPayloadToCommentRuntime));
      state.commentsAsyncState = 'loadedScope';
    });
    builder.addCase(addComment.rejected, (state, { payload }) => {
      state.commentsAsyncState = 'error';
    });
    // #endregion
    // #region "loadPlanComments" loads a specific planId[]'s comments, used for admin view
    builder.addCase(loadPlanComments.pending, (state) => {
      commentsAdapter.setAll(state, []);
      state.commentsAsyncState = 'loadingPlanComments';
      state.commentsPlanId = null;
    });
    builder.addCase(loadPlanComments.fulfilled, (state, { payload }) => {
      // TODO: add parsing and do this there
      const commentsWithData = payload[1].map(commentPayloadToCommentRuntime);
      commentsAdapter.setAll(state, commentsWithData);
      state.commentsAsyncState = 'loadedPlanComments';
      state.commentsPlanId = payload[0];
    });
    builder.addCase(loadPlanComments.rejected, (state, { payload }) => {
      state.commentsAsyncState = 'error';
      state.commentsPlanId = null;
    });
    // #endregion
  }
});
export const {
  resetComments
} = commentsSlice.actions;

const commentPayloadToCommentRuntime = (comment: Comment): Comment => {
  return {
    ...comment,
    modifiedAt: dayjs(comment.modifiedAt)
  };
};

export const commentsSelectors = commentsAdapter.getSelectors();

export default commentsSlice.reducer;
