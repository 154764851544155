import _ from 'lodash';
import React from 'react';
import Loadable, { LoadingComponentProps } from 'react-loadable';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AppState } from '../store';
import { loading } from './Loading';
import '../public-path'; // for code splitting to work properly on CDN
import Sidebar from './Sidebar/Sidebar';
import { ViewEntry, ViewParams } from 'state/ViewConfig/ViewConfig.types';
import { AxiosEnv, ServiceEnv, AuthContext } from 'services';
import { PerspectivePaths } from 'utils/domain/constants';
import RightContainer from './RightContainer/RightContainer';

export interface ILoadingValueProps {
  activeViews: ViewEntry[] | undefined
}

export type MFPViewPathNameSegments = {
  perspective?: PerspectivePaths,
  tab?: string,
  view?: string
}

export interface ILoadingOwnProps {
  // We must require the full ServiceEnv here to ensure that child dynamic load components
  // can receive the full subset they need
  serviceEnv: ServiceEnv
}

export type ILoadingProps = ILoadingValueProps & ILoadingOwnProps & RouteComponentProps<{
  perspective?: string,
  tab?: string,
  view?: string
}>;
export interface ViewProps {
  viewParams?: ViewParams,
  serviceEnv: ServiceEnv
}

function mapStateToProps(state: AppState) {
  return {
    activeViews: state.viewConfigSlice.activeViews
  };
}
class LoadingView extends React.Component<ILoadingProps> {
  public static sidebarWrapper(
    element: JSX.Element,
    tab: string,
    view: string,
    serviceEnv: AxiosEnv
  ) {
    return (
      <TransitionGroup className="content-area">
        <Sidebar tab={tab} serviceEnv={serviceEnv} />
        <main className="page">{element}</main>
        <RightContainer />
      </TransitionGroup>
    );
  }

  public render() {
    const loadingWithLogging = (props: LoadingComponentProps) => loading(this.props.serviceEnv.logging, props);
    const params = this.props.match.params;
    const mView = _.find(
      this.props.activeViews,
      view => params.tab === view.id
    );

    if (!mView) {
      return <React.Fragment />;
    }
    if (params.view === 'favorite') {
      const TemplateView = Loadable({
        loading: loadingWithLogging,
        loader: () => import('../views/templates/favorite')
      }) as React.ComponentClass<any>;
      if (params.tab) {
        return LoadingView.sidebarWrapper(
          // We forcibly set this here so that we may pass the service env down
          // This is unsound
          <TemplateView serviceEnv={this.props.serviceEnv} />,
          params.tab,
          params.view,
          this.props.serviceEnv
        );
      }
    }

    const mSubView = _.find(
      _.flatMap(mView.views, v => v.views),
      // @ts-ignore
      view => params.view === view.id
    );

    if (!mSubView) {
      if (mView.templateId) {
        const TemplateView = Loadable({
          loading: loadingWithLogging,
          loader: () => import(`../views/templates/${mView.templateId}`)
          // We forcibly set this here so that we may pass the service env down
          // This is unsound
        }) as React.ComponentClass<ViewProps & { serviceEnv: ServiceEnv }>;
        return (
          <div className={'content-area'}>
            <TemplateView viewParams={mView.viewParams} serviceEnv={this.props.serviceEnv} />
          </div>
        );
      }

      const View = Loadable({
        loading: loadingWithLogging,
        loader: () => import(`../views/${params.tab}`)
      }) as React.ComponentClass<ViewProps & { serviceEnv: ServiceEnv }>;
      return (
        <div className={'content-area'}>
          <View viewParams={mView.viewParams} serviceEnv={this.props.serviceEnv} />
        </div>
      );
    }

    const SubView = Loadable({
      loading: loadingWithLogging,
      loader: () => import(`../views/${params.tab}/${params.view}`)
    }) as React.ComponentClass<ViewProps & { serviceEnv: ServiceEnv }>;

    if (mSubView.templateId) {
      const TemplateView = Loadable({
        loading: loadingWithLogging,
        loader: () => import(`../views/templates/${mSubView.templateId}`)
      }) as React.ComponentClass<ViewProps>;
      if (params.tab && params.view) {
        return LoadingView.sidebarWrapper(
          <TemplateView viewParams={mSubView.viewParams} serviceEnv={this.props.serviceEnv} />,
          params.tab,
          params.view,
          this.props.serviceEnv
        );
      }
    }

    const content = <SubView viewParams={mSubView.viewParams} serviceEnv={this.props.serviceEnv} />;
    return LoadingView.sidebarWrapper(content, params.tab!, params.view!, this.props.serviceEnv);
  }
}
const ConnectLoadingView = connect(mapStateToProps)(LoadingView);

export default function ContextLoadingView(props: any) {
  const value = React.useContext(AuthContext);
  if (value) {
    return <ConnectLoadingView
      {...props}
      serviceEnv={value.serviceEnv}
    />;
  }
  return <React.Fragment />;
}
