import { requestChart, ChartDimensionConfig, setChartDataStale } from './EditableChart.slice';
import { Epic, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { AppState, EpicDependencies } from 'store';
import { getScopeReadyData, ServerScope } from 'state/scope/Scope.types';
import {
  getAvailableListing,
  DimensionItem,
  getEffeciveGroups,
  getGroupFromConfigItem
} from 'components/PivotConfigurator/utils';
import { VisualizeSummaryPivotViewParams } from 'views/templates/visualize-summary-pivot-ag.types';
import _ from 'lodash';
import PivotConfig from 'pivot/PivotConfig';
import PivotManager from 'pivot/Pivot.client';
import { AxiosInstance } from 'axios';
import { NEVER, Observable, of } from 'rxjs';
import { AnyAction } from 'redux';
import { SettingsState } from 'state/settings/settings.slice';
import { requestRefreshGrid } from 'state/scope/Scope.slice';

export const refreshChartPivot: Epic<any, any, AppState, EpicDependencies> =
(action$, state$, deps):Observable<AnyAction | Observable<never>> => {
  return action$.pipe(
    ofType(requestChart.type, requestRefreshGrid.type),
    concatMap(() => {
      const readyScope = getScopeReadyData(state$.value.scope);

      if (readyScope) {
        return of(setChartDataStale());
      }
      return NEVER;
    }
    ));
};

export function buildChartPivotManager(
  mainConfig: ServerScope,
  settings: SettingsState['entriesByKey'],
  chartDimensionConfig: ChartDimensionConfig,
  scopeId: string,
  client: AxiosInstance
) {
  const all = getAvailableListing(mainConfig, settings);

  const rows: DimensionItem[] = [
    chartDimensionConfig.metricDimensions,
    chartDimensionConfig.versions
  ];

  const columns = [chartDimensionConfig.xAxisDimension];
  const viewParams: VisualizeSummaryPivotViewParams = {
    title: 'Visualization',
    rows,
    columns,
    summaryMetrics: {} as any
  };

  const effective = getEffeciveGroups(
    all,
    viewParams
  );
  const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
  const pivotRows = effective.row.map(mi =>
    getGroupFromConfigItem(mi, viewParams, levelsMap)
  );
  const pivotCols = effective.column.map(mi =>
    getGroupFromConfigItem(mi, viewParams, levelsMap)
  );
  const pivotConfig = new PivotConfig({
    scopeId: scopeId,
    rows: pivotRows,
    columns: pivotCols
  });
  const pivotManager = new PivotManager({
    config: pivotConfig,
    axios: client,
    pivotName: 'ChartPivot'
  });
  return pivotManager;
}
