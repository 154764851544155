import { ofType } from 'redux-observable';
import { filter, mergeMap, map } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { AppEpic } from 'epics';
import { receivedCreateScope, receivedScope } from 'state/scope/Scope.slice';
import { getScopeReadyData } from 'state/scope/Scope.types';
import { setLastScopeCreated } from './UiPose.slice';
import { inputIsNotNullOrUndefined } from 'state/ViewConfig/ViewConfig.epics';

export const saveLastCreatedScope: AppEpic =
  (action$, state$) => {
    return action$.pipe(
      ofType(receivedCreateScope.type, receivedScope.type),
      map(() => getScopeReadyData(state$.value.scope)),
      filter(inputIsNotNullOrUndefined),
      mergeMap((newScope) => {
        const viewState = state$.value.viewConfigSlice;
        if (!viewState.currentPerspective || !newScope.currentAnchors) { return EMPTY; }
        return of(setLastScopeCreated([viewState.currentPerspective, newScope.currentAnchors]));
      })
    );
  };
