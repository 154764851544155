import { ofType } from 'redux-observable';
import { concatMap, filter, throttleTime, mergeMap, catchError, map } from 'rxjs/operators';
import { NEVER, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AppEpic } from 'epics';
import { receivedWorkingSets, WorkingSetContext } from 'state/workingSets/workingSets.slice';
import { CONTEXT_READY } from 'state/workingSets/workingSets.types';
import { AnyAction } from '@reduxjs/toolkit';
import { hasScopeId, getScopeReadyData, getScopeId } from './Scope.types';
import {
  receivedEopOptions,
  receivedScope,
  requestRefreshGrid
} from './Scope.slice';
import Scope, { SCOPECREATE_WITH_WP } from 'services/Scope.client';
import { toast } from 'react-toastify';
import { SCOPETYPE_ACTUALS } from 'utils/domain/constants';
import { isWorkflowBalance, Workflows } from './codecs/Workflows';
import { getLastTimeMember, SeedActuals } from './ScopeManagement.slice';
import { inputIsNotNullOrUndefined } from 'state/ViewConfig/ViewConfig.epics';
import { receivedAvailableScopes } from 'state/ViewConfig/ViewConfig.slice';
import { planToSeedPlan } from 'state/scope/codecs/projections/PlanMetadataToSeedPlan';
import { findEarliestPlans } from 'components/Scopebar/ScopeUtils';
import { isEmpty } from 'lodash';
import { fetchWorkflows } from './Scope.actions';

// throttle incoming events that can trigger data refreshes
// as sometimes many events can come in rapid succession from the server
// note that both the grid and the macrosummaries listen for the output 'requestRefreshGrid' here
// so they can both eventually be refreshed from the output action
export const refreshGrid: AppEpic =
  (action$, state$): Observable<AnyAction> => {
    return action$.pipe(
      ofType(receivedWorkingSets.type, receivedScope.type),
      filter(() => {
        // filter only 'ready' events for the current scope,
        // because we only want to refresh when the grid is ready
        const currentScopeId = getScopeId(state$.value.scope);
        if (currentScopeId) {
          const workingsets = state$.value.workingSets.contexts;
          const newScopeStatus = workingsets
            .filter((s) => s.initParams.type === SCOPECREATE_WITH_WP)
            .find(ws => ws.id === currentScopeId)?.status;
          return newScopeStatus === CONTEXT_READY;
        }
        return false;
      }),
      throttleTime(48, undefined, { // the first action fires, but events that come within three frames are ignored
        leading: true, // take the first one
        trailing: false // dont take the last one
      }),
      concatMap(() => of(requestRefreshGrid()))
    );
  };

export const setEopActuals: AppEpic =
  (action$, state$, deps): Observable<AnyAction> => {
    return action$.pipe(
      ofType('scope/fetchWorkflows/fulfilled', receivedAvailableScopes.type),
      filter(() => !!getScopeReadyData(state$.value.scope)),
      map((action) => {
        const readyScope = getScopeReadyData(state$.value.scope);
        if (readyScope) {
          const workflowPlans = action.type === 'scope/fetchWorkflows/fulfilled' ?
            (action.payload as unknown as Record<number, Workflows>) :
            readyScope.workflows;
          const maybeTimeMembers = state$.value.viewConfigSlice.availableMembers?.space.time;

          const plans = [...readyScope.mainConfig?.initializedPlans, ...readyScope.mainConfig.uninitializedPlans];
          if (isEmpty(plans) || !maybeTimeMembers) {
            return;
          }
          const balanceOptionsRecord = findEarliestPlans(plans).map((earlyPlan) => {
            const earliestPlanData = workflowPlans[earlyPlan.id];
            const plansOnly = earliestPlanData && !Array.isArray(earliestPlanData) ?
              earliestPlanData.plans :
              earliestPlanData;
            const newBalanceOptions = plansOnly ? plansOnly.filter(isWorkflowBalance)
              .map((pln) => {
                return planToSeedPlan(pln.plan, earlyPlan.id);
              }) : [];
            const maybeCurrentScopeTime = readyScope.mainConfig.memberTrees.time[0].data[0].v.id;
            if (maybeCurrentScopeTime && maybeTimeMembers) {
              const previousTime = getLastTimeMember(maybeTimeMembers, maybeCurrentScopeTime);

              // SPIKE: do we need both init and unint plans here?
              const balanceLyActuals: SeedActuals = {
                seedType: SCOPETYPE_ACTUALS,
                seedTime: previousTime.id,
                name: SCOPETYPE_ACTUALS,
                planId: null,
                applyTo: earlyPlan.id
              };
              return [[Number(earlyPlan.id)], [...newBalanceOptions, balanceLyActuals]];
            }
          });
          const plansMap = new Map(balanceOptionsRecord.map((plan, idx) => [plan![0], plan![1]]));
          return Object.fromEntries(plansMap);
        }
      }),
      filter((opts) => inputIsNotNullOrUndefined(opts) && !isEmpty(opts)),
      mergeMap((newOpts) => of(receivedEopOptions(newOpts)))
    );
  };
