import { ViewConfig, ViewEntry, FacetEntry } from './ViewConfig.types';
import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import { clone, defaultTo, isNil } from 'lodash';
import { matchPath, match, RouteProps } from 'react-router';
import { MFPViewPathNameSegments } from 'components/LoadingView';
import { ScopeMemberInfo, AvailableMembers, Workflow } from 'services/Scope.client';
import { Space } from 'space';
import { PerspectivePaths } from 'utils/domain/constants';
import qs from 'query-string';

export const API_BASE_URL = '/finplan/api' as const;

export type ViewConfigSlice = Readonly<{
  viewConfig: ViewConfig,
  facet?: FacetEntry,
  activeViews?: ViewEntry[],
  currentPerspective: PerspectivePaths | null,
  currentViewId: string | null,
  currentTabId: string | null,
  currentFavoriteId: string | null,
  currentViewHasSummary: boolean,
  availableMembers: AvailableMembers | undefined,
  controlPoints: { [key: string]: Space<ReadonlyArray<ScopeMemberInfo>> } | undefined,
  inSeason: string | undefined,
  error: string | undefined,
  previousView: {
    perspective: PerspectivePaths | null,
    viewId: string | null,
    tabId: string | null
  } | null
}>;

const DEFAULT_TITLE = 'MFP';
const initViewConfig = { views: {}, mfpApi: '/', facets: [], title: DEFAULT_TITLE };
const initialState: ViewConfigSlice = {
  viewConfig: initViewConfig,
  activeViews: [],
  currentPerspective: null,
  currentTabId: null,
  currentViewId: null,
  currentFavoriteId: null,
  facet: undefined,
  currentViewHasSummary: false,
  availableMembers: undefined,
  controlPoints: undefined,
  inSeason: undefined,
  error: undefined,
  previousView: null
};


const requestAvailableScopesCase = (state: ViewConfigSlice): ViewConfigSlice => {
  return {
    ...state,
    availableMembers: undefined,
    inSeason: undefined
  };
};
const receivedAvailableScopesCase =
  (state: ViewConfigSlice, action: PayloadAction<AvailableMembers>): ViewConfigSlice => {
    return {
      ...state,
      availableMembers: action.payload,
      controlPoints: action.payload.controlPoints,
      inSeason: action.payload.inSeason
    };
  };

const viewConfigSliceReducer = createSlice({
  name: 'viewConfig',
  initialState: initialState,
  reducers: {
    appReady: (state) => state,
    requestAvailableScopes: requestAvailableScopesCase,
    receivedAvailableScopes: receivedAvailableScopesCase,
    setActiveViews: (state, action: PayloadAction<string>) => {
      const newActiveViews = state.viewConfig?.views[action.payload] || [];
      return {
        ...state,
        activeViews: newActiveViews
      };
    },
    setViewConfig: {
      prepare: (viewConfig: ViewConfig, currentPerspective: string | null, workflow: Workflow | null | false) => {
        const facet = getFacetState(viewConfig, currentPerspective);
        let activeViews: ViewEntry[] | undefined;
        if (facet && workflow) {
          activeViews = viewConfig.views[`${facet.id}-${workflow}`];
        }
        // sort them when they come in
        viewConfig.facets = viewConfig.facets.sort((l, r) => {
          return defaultTo(l.sortIndex, 999) - defaultTo(r.sortIndex, 999);
        });
        document.title = viewConfig.title || DEFAULT_TITLE;
        return {
          payload: {
            viewConfig,
            facet: facet,
            activeViews
          }
        };
      },
      reducer: (state: ViewConfigSlice, action: PayloadAction<ViewConfigPayload>) => {
        const maybeNewActiveViews = action.payload.activeViews ? action.payload.activeViews : state.activeViews;

        return {
          ...state,
          viewConfig: action.payload.viewConfig,
          facet: action.payload.facet,
          activeViews: maybeNewActiveViews
        };
      }
    },
    setViewConfigFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
        viewConfig: state.viewConfig ? state.viewConfig : initViewConfig,
        activeViews: state.activeViews ? state.activeViews : []
      };
    }
  },
  extraReducers: {
    [(LOCATION_CHANGE)]: (state, action: LocationChangeAction) => {
      // this resolves some state properties from the current path
      const pathName = action.payload.location.pathname;
      const search = qs.parse(action.payload.location.search);
      const maybeFavoriteId = search.favoriteId && typeof search.favoriteId === 'string' && search.favoriteId || null;

      // TODO: move this to a view config property (derived or otherwise)
      const hasSummary = pathName.includes('planning-views')
      || pathName.includes('administration');
      const pathMatch = matchViewConfigPath(action.payload.location.pathname);
      let currentFacet = state.facet;

      const previousView = {
        perspective: clone(state.currentPerspective),
        viewId: clone(state.currentViewId),
        tabId: clone(state.currentTabId)
      };

      const perspectivePathSlot = (pathMatch && pathMatch.params.perspective) ? pathMatch.params.perspective : null;
      const tabId = (pathMatch && pathMatch.params.tab) ? pathMatch.params.tab : null;
      const viewId = (pathMatch && pathMatch.params.view) ? pathMatch.params.view : null;

      if (perspectivePathSlot && state.viewConfig) {
        const maybeFacet = state.viewConfig.facets.find(v => v.pathSlot === perspectivePathSlot);
        currentFacet = maybeFacet ? maybeFacet : currentFacet;
      }

      return {
        ...state,
        currentPerspective: perspectivePathSlot,
        currentViewId: viewId,
        currentTabId: tabId,
        currentViewHasSummary: hasSummary,
        facet: currentFacet,
        previousView: previousView,
        currentFavoriteId: maybeFavoriteId
      };
    }
  }
});

export const MFP_PATH_ROUTE = '/:perspective?/:tab?/:view?';
const mfpViewConfigRouteProps: RouteProps = {
  path: MFP_PATH_ROUTE,
  exact: true, // pathname has to match exact params,
  strict: false // ignores trailing slash
};
export const matchViewConfigPath = (pathName: string): match<MFPViewPathNameSegments> | null => {
  return matchPath<MFPViewPathNameSegments>(pathName, mfpViewConfigRouteProps);
};

type ViewConfigPayload = {
  viewConfig: ViewConfig,
  facet: FacetEntry | undefined,
  activeViews: ViewEntry[] | undefined
}

export const isLocationChange = (action: AnyAction): action is LocationChangeAction => {
  return action.type === LOCATION_CHANGE;
};

function getFacetState(viewConfig: ViewConfig, facetPathSlot: string | null): FacetEntry | undefined {
  const facets = viewConfig.facets || [];
  if (!facets.length || !facetPathSlot) {
    return undefined;
  }
  if (facetPathSlot) {
    const facet = facets.find(f => f.pathSlot === facetPathSlot);
    if (facet) {
      return facet;
    }
  }
  return undefined;
}

export const {
  setActiveViews,
  setViewConfig,
  setViewConfigFailure,
  appReady,
  receivedAvailableScopes,
  requestAvailableScopes
} = viewConfigSliceReducer.actions;

export default viewConfigSliceReducer.reducer;
