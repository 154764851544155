// css imports
import { LicenseManager } from 'ag-grid-enterprise';
import LoadingMask from 'components/LoadingMask/LoadingMask';
import { Logout } from 'components/Logout/Logout';
import {
  ConnectedRouter
} from 'connected-react-router';
import {
  existingAccessToken$,
  explicitLogin,
  explicitLogout$,
  getLatestAccessToken,
  isUnauthorized,
  loginError$,
  logout,
  startup
} from 'platform';
import qs from 'query-string';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import rootReducer from 'reducers';
import { Action } from 'redux';
import { asyncScheduler, combineLatest, firstValueFrom } from 'rxjs';
import { subscribeOn, take } from 'rxjs/operators';
import 'services/i18n.service';
import { getScope } from 'state/scope/Scope.actions';
import { createInitScopeState } from 'state/scope/Scope.slice';
import { startSse } from 'state/serverevents/ServerEventSource';
import '../node_modules/@fortawesome/fontawesome-pro/css/all.css';
import '../node_modules/@trendmicro/react-modal/dist/react-modal.css';
import '../node_modules/@trendmicro/react-toggle-switch/dist/react-toggle-switch.css';
import '../node_modules/npm-font-open-sans/open-sans.scss';
import { createMfpStore } from '../src/store';
// import { Auth } from './auth/Auth';
import browserHistory from './browserHistory';
import Main from './components/MainContainer/MainContainer';
import { AuthContext, createServiceEnv, ServiceEnv } from './services';
import './_global.scss';


// special guard for a jest error
if (process.env.JEST_WORKER_ID === undefined) {
  LicenseManager.setLicenseKey(
    'S5_Stratos_MultiApp_2Devs11_July_2019__MTU2Mjc5OTYwMDAwMA==82681080271234c6108c212ac1a9f2c7'
  );
}


const LOGIN_RETRY_TIME = 200;

type AppShellState = {
  explicitLogout: boolean,
  loginError: boolean,
  // When defined, initialization has been successful
  initSuccess?: InitializedState
};

type InitializedState = {
  serviceEnv: ServiceEnv,
  store: ReturnType<typeof createMfpStore>
};

class AppShellContainer extends React.Component<{}, AppShellState> {

  state: AppShellState = {
    explicitLogout: false,
    loginError: false
  };

  constructor(props: {}) {
    super(props);
    startup();
  }

  // Now we need to perform initialization
  public componentDidMount() {
    combineLatest([loginError$, explicitLogout$])
      // Ensure delay so that we don't use our setState
      .pipe(subscribeOn(asyncScheduler))
      .subscribe(([loginError, explicitLogout]) => {
        this.setState({ ...this.state, loginError, explicitLogout });
      });


    const existingToken = () => firstValueFrom(existingAccessToken$.pipe(take(1)));
    const serviceEnv = createServiceEnv('/', existingToken, getLatestAccessToken, () => this.onUnauthorized());
    const initScopeId = qs.parse(window.location.hash).scope as string;
    const initState = {
      scope: createInitScopeState(initScopeId)
    } as ReturnType<typeof rootReducer>;
    const store = createMfpStore(serviceEnv, initState);
    if (initScopeId) {
      // ssssh... go to sleep tsc
      store.dispatch(getScope(initScopeId) as unknown as Action<string>);
    }

    startSse(store.dispatch, existingAccessToken$);
    this.setState({ ...this.state, initSuccess: { store, serviceEnv } });
  }

  private onUnauthorized() {
    isUnauthorized();
  }

  private logout() {
    logout();
  }

  private onLogin() {
    explicitLogin();
  }

  render() {
    if (this.state.explicitLogout) {
      return <Logout message={'You are logged out'} onLogin={() => this.onLogin()} />;
    } else if (this.state.loginError) {
      return <Logout message={'An error occurred during authentication'} onLogin={() => this.onLogin()} />;
    } else if (this.state.initSuccess) {
      const authContextPayload = {
        serviceEnv: this.state.initSuccess.serviceEnv,
        // authorization is required for initSuccess as in on componentDidMount
        lastBearerToken: getLatestAccessToken,
        logout: () => this.logout()
      };
      return (
        <AuthContext.Provider value={authContextPayload}>
          <ProviderRouterComponent store={this.state.initSuccess.store}>
            <Main serviceEnv={this.state.initSuccess.serviceEnv} />
          </ProviderRouterComponent>
        </AuthContext.Provider>
      );
    } else {
      return (<LoadingMask />);
    }
  }
}


interface MfpAppProps {
  store: ReturnType<typeof createMfpStore>,
  children?: React.ReactElement<Provider>
}

class ProviderRouterComponent extends React.PureComponent<MfpAppProps> {
  public render() {
    return (
      <Provider store={this.props.store}>
        <ConnectedRouter history={browserHistory}>
          <Switch>
            <Route
              path="/"
              render={props => {
                return React.cloneElement(this.props.children!, {
                  ...props,
                  ...this.props.children!.props
                });
              }}
            />
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default ReactDOM.render(<AppShellContainer />, document.getElementById('app'));
