import * as React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch
} from 'react-router';
import PerspectiveSelection from 'views/PerspectiveSelection/PerspectiveSelection';
import { Logout } from 'components/Logout/Logout';
import LoadingView from '../LoadingView';
import { ViewConfig, FacetEntry } from 'state/ViewConfig/ViewConfig.types';

type RouteBuilderProps = {
  facetConfig: ViewConfig['facets'],
  location: RouteComponentProps['location']
};

export const RouteBuilder = (props: RouteBuilderProps) => {
  const location = props.location;
  const facets = props.facetConfig;

  return (
    <Switch location={location}>
      <Redirect from="/auth_token=*" to="/" />
      <Route
        exact={true}
        path="/"
        component={PerspectiveSelection}
      />
      <Route
        exact={true}
        path="/select"
        component={PerspectiveSelection}
      />
      {facets.map((facet: FacetEntry) => {
        return (
          <Route
            key={facet.id}
            path={`/${facet.pathSlot}/:tab/:view?`}
            component={LoadingView}
            exact={true}
          />
        );
      })}
      <Redirect from="/*" to="/select" />
    </Switch>
  );
};
