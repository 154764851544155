import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TopMembers } from 'services/Scope.client';
import { PerspectivePaths, TOP_DOWN } from 'utils/domain/constants';

export interface SettingsState {
  sidebarExpanded: boolean,
  lastScopeCreated: Record<PerspectivePaths, TopMembers>
}

const initialState: SettingsState = {
  sidebarExpanded: true,
  lastScopeCreated: {} as Record<PerspectivePaths, TopMembers>
};

const uiPoseSlice = createSlice({
  name: 'uiPose',
  initialState: initialState,
  reducers: {
    setSidebarExpanded: (state) => {
      state.sidebarExpanded = true;
    },
    setSidebarNotExpanded: (state) => {
      state.sidebarExpanded = false;
    },
    toggleSidebarExpanded: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
    setLastScopeCreated: (state, action: PayloadAction<[PerspectivePaths, TopMembers]>) => {
      state.lastScopeCreated[action.payload[0]] = action.payload[1];
    }
  }
});

export const {
  setSidebarExpanded,
  setSidebarNotExpanded,
  toggleSidebarExpanded,
  setLastScopeCreated
} = uiPoseSlice.actions;

export default uiPoseSlice.reducer;
