import axios, { AxiosInstance, AxiosError } from 'axios';
import * as React from 'react';

import { Lazy } from 'fp-ts/lib/function';
import ConfigService from './Config.service';
import Settings from './Settings';
import { Lens } from 'monocle-ts';
import { identity } from 'io-ts';
import numeral from 'utils/numbro';
import LoggingService from './Logging.service';
import AdminService from './Admin.service';
import CommentsService from '../components/Comments/Comments.service';
import PivotManager from 'pivot/Pivot.client';

export type AxiosEnv = { axios: AxiosInstance, lastBearerToken: () => string | null };
export type AdminEnv = { admin: AdminService };
export type CommentsEnv = { comments: CommentsService }
export type ConfigEnv = { config: ConfigService };
export type SettingsEnv = { settings: Settings };
export type LocalizationEnv = { localization: typeof numeral };
export type LoggingEnv = { logging: LoggingService };
export interface MacroSummaryPivots {
  macroData: Record<string, PivotManager>
}
export type ServiceEnv = AxiosEnv &
AdminEnv &
CommentsEnv &
ConfigEnv &
SettingsEnv &
LocalizationEnv &
LoggingEnv &
MacroSummaryPivots;

export interface AuthContextPayload {
  serviceEnv: ServiceEnv,
  lastBearerToken: () => string | null,
  logout: Lazy<void>
}

// <Main> is not mounted until we have a full AuthContext, but <Index> can go through a few renders
// before we have that.  This context won't be reliable above main, but otherwise should be safe
// therefore we assert here to avoid having to ! the context in every useage
// Note: this doesn't work with class component static contextType for reasons that are unclear to me
export const AuthContext = React.createContext<AuthContextPayload>({} as AuthContextPayload);


function createAxiosClient(baseUrl: string, currentBearerToken: () => Promise<string>,
  onUnauthorized: Lazy<void>): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'App-Client': 'mfp'
    }
  });
  axiosInstance.interceptors.request.use(async (config) => {
    const token = await currentBearerToken();
    let headers = config.headers || {};
    config.headers = {
      ...headers,
      'authorization': `Bearer ${token}`
    };
    return config;
  });
  axiosInstance.interceptors.response.use(
    identity, // We don't care about successful responses, so let them just go through
    (error) => {
      if (isUnauthorized(error)) {
        onUnauthorized();
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
}

const isUnauthorized = (error: AxiosError) => {
  return error.response && error.response.status === 401;
};

export function createServiceEnv(
  baseUrl: string,
  currentBearerToken: () => Promise<string>,
  lastBearerToken: () => string | null,
  onUnauthorized: Lazy<void>
): ServiceEnv {
  const axios = createAxiosClient(baseUrl, currentBearerToken, onUnauthorized);
  const admin = new AdminService(axios);
  const comments = new CommentsService(axios);
  const config = new ConfigService(axios);
  const settings = new Settings(axios);
  const logging = new LoggingService(axios);
  const localization = numeral;
  const macroData = {};

  return { axios, lastBearerToken, admin, comments, config, settings, localization, logging, macroData };
}


