import { Dispatch } from 'redux';
import Favorites, { FavoritesEntry } from '../../services/Favorites';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { AppDispatch } from 'store';

export interface FavoritesState {
  loaded: boolean,
  favorites: FavoritesEntry[]
}

export const favoritesSlice = createSlice({
  name: 'favorites_state',
  initialState: { favorites: [], loaded: false } as FavoritesState,
  reducers: {
    REQUEST_FAVORITES: (state) => ({ ...state, favorites: [] }),
    // eslint-disable-next-line max-len
    RECEIVED_FAVORITES: (state, action: PayloadAction<FavoritesEntry[]>) => ({ ...state, favorites: action.payload, loaded: true })
  }
});
export const { REQUEST_FAVORITES, RECEIVED_FAVORITES } = favoritesSlice.actions;

export function loadFavorites(axios: AxiosInstance) {
  return (dispatch: AppDispatch) => {
    dispatch(REQUEST_FAVORITES());
    return new Favorites(axios).ajaxLoad().then(entries => {
      return dispatch(RECEIVED_FAVORITES(entries));
    });
  };
}

export function createFavorite(axios: AxiosInstance, fav: FavoritesEntry) {
  return (dispatch: AppDispatch) => {
    return new Favorites(axios).create(fav).then(updatedFavorites => {
      dispatch(RECEIVED_FAVORITES(updatedFavorites));
    });
  };
}

export function mutateFavorite(axios: AxiosInstance, fav: FavoritesEntry) {
  return (dispatch: AppDispatch) => {
    return new Favorites(axios).updateFavorite(fav).then(updatedFavorites => {
      dispatch(RECEIVED_FAVORITES(updatedFavorites));
    });
  };
}

export function deleteFavorite(axios: AxiosInstance, key: string) {
  return (dispatch: AppDispatch) => {
    return new Favorites(axios).delete(key).then(updatedFavorites => {
      dispatch(RECEIVED_FAVORITES(updatedFavorites));
    });
  };
}

export default favoritesSlice.reducer;
