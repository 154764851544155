import React, { useContext } from 'react';
import { SidebarContext } from './SidebarContext';
import LinksSection from './LinksSection';

const SidebarLinks = () => {
  const { items = [] } = useContext(SidebarContext);

  return (
    <ul className="sidebar-links-list">
      {items.map(item => {
        return <LinksSection key={item.title} links={item} />;
      })}
    </ul>
  );
};

export default SidebarLinks;
