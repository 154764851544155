import * as iots from 'io-ts';
import { UserDetails } from './UserDetails';
import { Lens } from 'monocle-ts';
import { DayJsFromString } from './DayJsFromString';
import { Workflow } from './Workflows';

const VERSION_WP = iots.literal('wp');
export const VERSION_SAVED = iots.literal('save');
const VERSION_DYNAMIC = iots.literal('dynamic');
const VERSION_RP = iots.literal('rp');
const VERSION_OP = iots.literal('op');
const VERSION_LP = iots.literal('lp');
const VERSION_SV = iots.literal('sv');
export const VERSION_SMART = iots.literal('smart');
// types below here may be removed/changed later, need to be dynamic

const ScopeType = iots.union([
  VERSION_WP,
  VERSION_SAVED,
  VERSION_DYNAMIC,
  VERSION_RP,
  VERSION_OP,
  VERSION_LP,
  VERSION_SV,
  VERSION_SMART,
  iots.string // catch all for new ids
]);

// A unique brand for planId
// this adds a little more type safety when shuffling these around
interface PlanIdBrand {
  /** The plan's unique identifier */
  readonly PlanId: unique symbol
}

export const PlanId = iots.brand(
  iots.number,
  (n): n is iots.Branded<number, PlanIdBrand> => 0 < n,
  'PlanId'
);

export type PlanId = iots.TypeOf<typeof PlanId>

const newSpace = iots.record(iots.string, iots.string);
export const PlanMetadata = iots.type({
  /** The plan's unique identifier */
  id: PlanId,
  /** The plan's name, can be the magic string 'unnamed' for system plans */
  name: iots.string,
  /** An incrementing number indicating the number of times the plan has been written,  zero indexed */
  mvccCounter: iots.Int,
  /** The plan's module */
  app: iots.string, //  TODO: make this dynamic
  /** The plan's space as string:string of top members, usually product, location, time, prodlife */
  space: newSpace, // TODO: make space strongly typed and dynamic
  /** The plan's scope type (also similar to version), wp, rp, op, etc. */
  version: ScopeType,
  /** The plan's current owner, can be 'system' for published corporate plans, in which email/name is null */
  ownedBy: UserDetails,
  /** The plan's original author, should always have a defined email/name */
  authoredBy: UserDetails,
  /** The plan's most recent modifier, usually the user that approved it */
  modifiedBy: UserDetails,
  /** The plan id that this plan was created from, if any */
  createdFrom: iots.union([PlanId, iots.null]),
  /** The timestamp of the fx rate the plan is currently using.
   * This represents the time at which the fx rate was applied to the plan */
  exchangeRateTimestamp: iots.union([DayJsFromString, iots.null]),
  /** The timestamp of when the plan was created */
  createdAt: DayJsFromString
}, 'PlanMetadata');

export type PlanMetadata = iots.TypeOf<typeof PlanMetadata>;
export type PlanMetadataNarrow = Pick<PlanMetadata, 'id' | 'version' | 'name'>
export const scopeTypeLens = Lens.fromProp<PlanMetadata>()('version');
