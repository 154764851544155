import { isEmpty, isNil, defaultTo } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import '../../components/Header/_Header.scss';
import LoadingMask from '../../components/LoadingMask/LoadingMask';
import { FacetEntry, ViewEntry } from 'state/ViewConfig/ViewConfig.types';
import './_PerspectiveSelection.styles.scss';
import { AppState } from 'store';

const blackTextLogo = require('../../images/S5Logo-Black-Inovation-Centered.png');

type FacetEntryWithDefault = FacetEntry & { defaultViewPath: string }
const findBottomView = (view: ViewEntry): ViewEntry => {
  if (view.views) {
    return findBottomView(view.views[0]);
  }
  return view;
};

const PerspectiveSelector = (props: PerspectiveSelectionProps) => {
  const views = props.viewConfig.views;
  const facetsWithDefault = props.viewConfig.facets.map((f) => {
    return {
      ...f,
      // in-season is always the default, don't look at this ryan
      // eslint-disable-next-line max-len
      defaultViewPath: `${f.pathSlot}/${views[`${f.id}-in-season`][0].id}/${findBottomView(views[`${f.id}-in-season`][0]).id}?facet=${f.id}`
    };
  });

  let error: JSX.Element | null = null;
  if (isNil(views) || isEmpty(facetsWithDefault)) {
    return <LoadingMask />;
  }

  if (facetsWithDefault.length === 1 || facetsWithDefault.filter(f => !f.disabled).length === 1) {
    const onlyFacet = facetsWithDefault.find(f => !f.disabled)!;
    return <Redirect to={onlyFacet.defaultViewPath} />;
  }
  if (facetsWithDefault.filter(f => !!f.disabled).length === facetsWithDefault.length) {
    error = <p>You do not have access to any products or locations.</p>;
  }

  const mapFacetToBubble = (facet: FacetEntryWithDefault) => {
    const disabled = defaultTo(facet.disabled, false);
    const to = disabled ? '#' : facet.defaultViewPath;

    return (
      <Link key={facet.id} data-id={facet.id} to={to} className={disabled ? 'facet-link disabled' : 'facet-link'}>
        <div>
          <span className="fa-stack fa-3x">
            <i className={facet.iconSrc} />
          </span>
        </div>
        <label>{facet.displayName}</label>
      </Link>
    );
  };
  const links = facetsWithDefault.map(mapFacetToBubble);

  return (
    <div className={'perspective-center'}>
      <img className={'brandLogoCentered'} src={blackTextLogo} alt="" />
      <div className={'perspective-select'}>{links}</div>
      {error}
    </div>
  );
};

export function mapStateToProps(state: AppState) {
  return {
    viewConfig: state.viewConfigSlice.viewConfig
  };
}
export type PerspectiveSelectionProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(PerspectiveSelector);
