import React from 'react';
import 'components/Header/_Header.scss';
import './_Logout.scss';
import { Button } from 'semantic-ui-react';

import BrandLogo from '../../images/S5Logo-White-NoInnovation-Centered.png';

export const Logout = (props: {message:string, onLogin: () => void}) => {
  return (
    <React.Fragment>
      <nav className={'navbar navbar-perspective'}>
        <a href="/" className="navbar-logo-link" data-qa="TopNavLogoLink">
          <img className="navbar-logo" src={BrandLogo} alt="S5 Logo" data-qa="TopNavHomeIcon" />
        </a>
      </nav>
      <div className={'logout'}>
        <h3>{props.message}</h3>
        <br></br>
        <Button onClick={props.onLogin}>
          Log back in
        </Button>
      </div>
    </React.Fragment>
  );
};
