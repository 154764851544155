import Comments from 'components/Comments/Comments';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store';
import './_RightContainer.scss';

const mapStateToProps = (state: AppState) => {
  return {
    content: state.rightContainer.content,
    isOpen: state.rightContainer.isOpen
  };
};
type RightContainerOwnProps = ReturnType<typeof mapStateToProps>;
type RightContainerProps = RightContainerOwnProps;

const RightContainer = (props: RightContainerProps) => {
  return (
    <div className={props.isOpen ? 'rightcontainer open' : 'rightcontainer'} >
      {(() => {
        switch (props.content.type) {
          case 'comments':
            return <Comments/>;
          case 'empty':
            return <div />;
          default:
            return <div />;
        }
      })()}
    </div>
  );
};

export default connect(mapStateToProps)(RightContainer);
