import { Epic, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { AppState, EpicDependencies } from 'store';
import { getScopeReadyData, ServerScope } from 'state/scope/Scope.types';
import {
  getAvailableListing,
  getEffeciveGroups,
  getGroupFromConfigItem,
  PivotOptions
} from 'components/PivotConfigurator/utils';

import _ from 'lodash';
import PivotConfig from 'pivot/PivotConfig';
import PivotManager from 'pivot/Pivot.client';
import { AxiosInstance } from 'axios';
import { NEVER, Observable, of } from 'rxjs';
import { AnyAction } from 'redux';
import { SettingsState } from 'state/settings/settings.slice';
import { requestRefreshGrid } from 'state/scope/Scope.slice';
import { ViewParams } from 'state/ViewConfig/ViewConfig.types';
import { setTrendSummaryDataStale } from './TrendSummary.slice';

export const trendSummaryRefresh: Epic<any, any, AppState, EpicDependencies> =
(action$, state$, deps):Observable<AnyAction | Observable<never>> => {
  return action$.pipe(
    ofType(requestRefreshGrid.type),
    concatMap(() => {
      const scopeReadyData = getScopeReadyData(state$.value.scope);
      if (scopeReadyData) {
        return of(setTrendSummaryDataStale());
      }
      return NEVER;
    }
    ));
};

export function buildChartPivotManager(
  mainConfig: ServerScope,
  settings: SettingsState['entriesByKey'],
  scopeId: string,
  client: AxiosInstance,
  viewParams: ViewParams
) {
  const all = getAvailableListing(mainConfig, settings);
  const rows = [viewParams.summaryMetrics.metrics];
  const columns =[viewParams.summaryMetrics.revisions];
  const viewParam: PivotOptions = {
    rows, columns
  };
  const effective = getEffeciveGroups(
    all,
    viewParam
  );
  const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
  const pivotRows = effective.row.map(mi =>
    getGroupFromConfigItem(mi, viewParam, levelsMap)
  );
  const pivotCols = effective.column.map(mi =>
    getGroupFromConfigItem(mi, viewParam, levelsMap)
  );
  const pivotConfig = new PivotConfig({
    scopeId: scopeId,
    rows: pivotRows,
    columns: pivotCols
  });
  const pivotManager = new PivotManager({
    config: pivotConfig,
    axios: client,
    pivotName: 'TrendSummaryPivot'
  });
  return pivotManager;
}


