import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import PivotConfigurator from '../components/PivotConfigurator/PivotConfigurator';
import { ConfigItem } from '../components/PivotConfigurator/PivotConfigurator.types';
import { findIndex, get, isEmpty, isNil, remove } from 'lodash';
import { API_BASE_URL } from 'state/ViewConfig/ViewConfig.slice';
import { PivotOptions } from 'components/PivotConfigurator/utils';

export interface FavoritesEntry {
  key: string, // identifier
  group: string, // what facet it applies to
  value: PivotOptions // the favorites
}

export interface FavoritesPayload {
  favorites: FavoritesEntry[]
}

const emptyFavorites = [] as FavoritesEntry[];

export default class Favorites {
  protected client: AxiosInstance;
  private readonly BASE = `${API_BASE_URL}/settings`;
  private readonly REST_STORAGE_KEY = 'user-pivot-layout-favorites';
  private readonly uri = `${this.BASE}/${this.REST_STORAGE_KEY}`;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public handleFavoritesResponse = (
    response: AxiosResponse<FavoritesPayload>
  ) => {
    if (typeof response.data === 'string' && response.data !== '') {
      response.data = JSON.parse(response.data);
    }
    const favorites = get(response, 'data.favorites');
    if (isNil(favorites) || isEmpty(favorites)) {
      return emptyFavorites;
    }
    return response.data.favorites;
  };
  public handleFavoritesError = (error: AxiosError) => {
    if (error.response && error.response.status === 404) {
      // api returns 404 when key not found, indicating no current favorites
      return emptyFavorites;
    }
    // TODO do better error handling here
    if (error.response) {
      throw new Error(`
      An error occured downloading favorites
      ${error.response.status}
      ${error.response.statusText}
      `);
    }
    throw new Error('An error occured downloading favorites');
  };

  public ajaxLoad(): Promise<FavoritesEntry[]> {
    return this.client
      .get<FavoritesPayload>(this.uri)
      .then(this.handleFavoritesResponse)
      .catch(this.handleFavoritesError);
  }

  public ajaxUpdate(favorites: FavoritesEntry[]) {
    const payload: FavoritesPayload = {
      // tslint:disable-next-line: object-literal-shorthand
      favorites: favorites
    };
    const stringifiedPayload = JSON.stringify(payload);

    return this.client
      .put(this.uri, stringifiedPayload)
      .then(() => {
        return this.ajaxLoad();
      })
      .catch(() => emptyFavorites);
  }

  public create(favorite: FavoritesEntry) {
    return this.ajaxLoad().then(values => {
      const newValues = [...values, favorite];
      return this.ajaxUpdate(newValues).then(newFavorites => {
        return newFavorites;
      });
    });
  }

  public updateFavorite(favorite: FavoritesEntry) {
    return this.ajaxLoad().then(values => {
      const favIndex = findIndex(values, fav => fav.key === favorite.key);
      values[favIndex] = favorite;
      return this.ajaxUpdate(values).then(newValues => {
        return newValues;
      });
    });
  }

  public delete(id: string) {
    return this.ajaxLoad().then(values => {
      remove(values, fav => {
        return fav.key === id;
      });
      return this.ajaxUpdate(values).then(newValues => {
        return newValues;
      });
    });
  }
}
export const configItemToSimpleFavorite = (
  configItem: ConfigItem,
  configurator: PivotConfigurator
) => {
  let items;
  // eslint-disable-next-line max-len
  if (configItem.children && !isEmpty(configItem.children) && (configItem.id === 'revisions' || configItem.id === 'metrics')) {
    const nonDisabledChildren = configItem.children!.filter(cg => !cg.disabled);
    items = nonDisabledChildren.map(i => i.id);
  } else {
    items = configurator.getVisibleLevels(configItem).map(id => `level:${id}`);
  }
  return {
    items,
    dimension: configItem.id
  };
};
