import React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import { toast } from 'react-toastify';
import LoggingService from '../services/Logging.service';


export function loading(logger: LoggingService, props: LoadingComponentProps) {
  if (props.error) {
    logger.error('Error: the client attempted to open a view that doesnt exist', props.error.stack);
    toast.error('We were unable to load the view, and an error report has been logged', {
      position: toast.POSITION.TOP_LEFT
    });
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  }
  return <div>Loading...</div>;
}
