import { requestAvailableScopes, receivedAvailableScopes } from 'state/ViewConfig/ViewConfig.slice';
import {
  WP
} from '../../utils/domain/constants';
import {
  GRID_SAVING,
  GRID_REFRESHING,
  GRID_REFRESHED,
  GridAsyncState,
  GRID_DEFAULT,
  ServerScopeResponse,
  isScopeReady,
  GRID_SAVED,
  GRID_ERROR,
  PersistMessage,
  ScopeStateUnion,
  scopeEmpty,
  scopePending,
  scopeReady,
  scopeBusy,
  scopeFailed,
  isReady,
  modifyScopeData,
  modifyScopeDataWithId,
  ScopeReady,
  getScopeReadyData
} from './Scope.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ScopeCreateRequest, TopMembers } from 'services/Scope.client';
import { AxiosInstance } from 'axios';
import {
  CONTEXT_BUSY,
  CONTEXT_FAILED
} from 'state/workingSets/workingSets.types';
import { SeedPlan, SeedActuals } from './ScopeManagement.slice';
import { planToSeedPlan } from './codecs/projections/PlanMetadataToSeedPlan';
import { memberToSeedActuals } from './codecs/projections/ScopeMemberToSeedActuals';
import { isWorkflowSeed, Workflows, WORKFLOW_IMPORT } from './codecs/Workflows';
import { mapValues, values, mergeWith } from 'lodash';
import { PlanId, PlanMetadata } from './codecs/PlanMetadata';

// TODO fix this now because it breaks with auth0 flow
// TODO replace this with a more legit solution to importing query params to state
export const createInitScopeState = (scopeId: string | null = null): ScopeStateUnion => {
  if (scopeId) {
    return scopePending(scopeId);
  }
  return scopeEmpty;
};

type ScopeIdPayload = {
  scopeId: string
}

type ScopeRequestPayload = ServerScopeResponse

type ScopeErrorPayload = ScopeIdPayload & {
  error: string
}

const gridSaving = (state: ScopeStateUnion): ScopeStateUnion => {
  return modifyScopeData(state, data => ({
    ...data,
    gridAsyncState: GRID_SAVING
  }));
};

const gridRefreshing = (state: ScopeStateUnion): ScopeStateUnion => {
  return modifyScopeData(state, data => ({
    ...data,
    gridAsyncState: GRID_REFRESHING
  }));
};
const gridRefreshed = (state: ScopeStateUnion): ScopeStateUnion => {
  return modifyScopeData(state, data => ({
    ...data,
    gridAsyncState: GRID_REFRESHED,
    forceRefreshGrid: false
  }));
};

const requestCreateScopeCase =
  (_state: ScopeStateUnion, _action: PayloadAction<ScopeCreateRequest>): ScopeStateUnion => {
    return scopeEmpty;
  };
const requestCreateSmartScopeCase =
  (state: ScopeStateUnion, _action: PayloadAction<ScopeCreateRequest>): ScopeStateUnion => {
    return state;
  };


export const anchorIdsFromMemberTrees = (trees: ScopeReady['mainConfig']['memberTrees']): TopMembers => {
  return mapValues(trees, ((tree, key) => {
    return tree[0].data.map((member) => member.v.id);
  }));
};
const isMultiScope = (topMembers: TopMembers): boolean => {
  // check if each dimension has exactly 1 member
  // if not, it's a multi-scope
  return !values(topMembers).every((mem) => mem.length === 1);
};
const receivedCreateScopeCase =
  (_state: ScopeStateUnion, action: PayloadAction<ServerScopeResponse>): ScopeStateUnion => {
    if (action.payload.type === 'ScopeReady') {
      const anchorMembers = anchorIdsFromMemberTrees(action.payload.memberTrees);
      return scopeReady(action.payload.id, {
        mainConfig: action.payload,
        currentAnchors: anchorIdsFromMemberTrees(action.payload.memberTrees),
        isMultiScope: isMultiScope(anchorMembers),
        isFetching: false,
        forceRefreshGrid: false,
        // TODO: Some of these things should be removed from the payload
        hasEditableRevision: action.payload.revisions.findIndex(r => r.version === WP) > -1 || false,
        inSeason: action.payload.inSeason,
        initialized: action.payload.initialized,
        gridAsyncState: GRID_DEFAULT,
        pendingWrites: 0,
        hasLocks: false,
        eopOptions: {},
        seedOptions: {},
        importOptions: {},
        overlayOptions: {},
        workflows: {},
        persistErrorStatus: undefined,
        message: undefined
      });
    } else {
      return scopePending(action.payload.id);
    }
  };

const requestScopeCase = (_state: ScopeStateUnion, action: PayloadAction<ScopeIdPayload>): ScopeStateUnion => {
  return scopePending(action.payload.scopeId);
};

type ReceivedScopePayload = ScopeRequestPayload & { hasEditableRevision: boolean, client: AxiosInstance };
// How to get this client out ofhere
const receivedScopeCase = {
  prepare: (
    scope: ServerScopeResponse,
    client: AxiosInstance
  ): { payload: ReceivedScopePayload } => {
    // figure out if there is an editable version in the return
    let hasEditableRevision = false;
    if (isScopeReady(scope)) {
      hasEditableRevision = scope.revisions.findIndex(r => r.version === WP) > -1 || false;
      return {
        payload: {
          client,
          hasEditableRevision,
          ...scope
        }
      };
    }
    // This is extremely weird that we jus tsmear everything across like this
    return {
      payload: {
        hasEditableRevision,
        client,
        ...scope
      }
    };
  },
  reducer: (
    state: ScopeStateUnion,
    action: PayloadAction<ReceivedScopePayload>
  ): ScopeStateUnion => {
    if (action.payload.type === 'ScopeReady') {
      // If the scope is ready then we can construct a body for most things
      const anchorMembers = anchorIdsFromMemberTrees(action.payload.memberTrees);
      const body = {
        hasEditableRevision: action.payload.hasEditableRevision,
        availableMembers: action.payload.members,
        currentAnchors: anchorMembers,
        isMultiScope: isMultiScope(anchorMembers),
        isFetching: false,
        inSeason: action.payload.inSeason,
        mainConfig: action.payload,
        initialized: action.payload.initialized,
        gridAsyncState: GRID_DEFAULT,
        pendingWrites: 0,
        // Probably unnecessary
        scopeReady: action.payload.scopeReady,
        forceRefreshGrid: false,
        hasLocks: false,
        eopOptions: {},
        seedOptions: {},
        workflows: {},
        importOptions: {},
        overlayOptions: {},
        message: undefined,
        persistErrorStatus: undefined
      };
      if (action.payload.status === CONTEXT_BUSY) {
        return scopeBusy(action.payload.id, body);
      } else if (action.payload.status === CONTEXT_FAILED) {
        return scopeFailed(action.payload.id, body);
      } else {
        return scopeReady(action.payload.id, body);
      }
    } else {
      return scopePending(action.payload.id);
    }
  }
};
const receivedScopeCaseDynamic = {
  prepare: (
    scope: ServerScopeResponse,
    client: AxiosInstance
  ): { payload: ReceivedScopePayload } => {
    // figure out if there is an editable version in the return
    let hasEditableRevision = false;
    if (isScopeReady(scope)) {
      hasEditableRevision = scope.revisions.findIndex(r => r.version === WP) > -1 || false;
      return {
        payload: {
          client,
          hasEditableRevision,
          ...scope
        }
      };
    }
    // This is extremely weird that we jus tsmear everything across like this
    return {
      payload: {
        hasEditableRevision,
        client,
        ...scope
      }
    };
  },
  reducer: (
    state: ScopeStateUnion,
    action: PayloadAction<ReceivedScopePayload>
  ): ScopeStateUnion => {
    if (action.payload.type === 'ScopeReady') {
      // If the scope is ready then we can construct a body for most things
      const body = {
        ...state,
        mainConfig: action.payload
      };
      return body;
    } else {
      return scopePending(action.payload.id);
    }
  }
};

const scopeNotFoundCase = (
  _state: ScopeStateUnion,
  action: PayloadAction<{ scopeId: string, error?: any }>
): ScopeStateUnion => {
  // TODO: log error here
  return scopeEmpty;
};
const clearScopeCase = (): ScopeStateUnion => {
  return scopeEmpty;
};

const updatePersistenceStatusCase =
  (state: ScopeStateUnion, action: PayloadAction<PersistMessage>): ScopeStateUnion => {
    return modifyScopeDataWithId(state, (id, data) => {
      const currentScopeStatus = action.payload[id];
      const newAsyncState = currentScopeStatus ?
        currentScopeStatus.inflight === 0 ?
          (currentScopeStatus.previousError == null ? GRID_SAVED : GRID_ERROR)
          : GRID_SAVING :
        GRID_DEFAULT;
      return {
        ...data,
        persistErrorStatus: currentScopeStatus?.previousError,
        gridAsyncState: newAsyncState,
        pendingWrites: currentScopeStatus.inflight
      };
    });
  };

const receivedSeedCurrentScopeCase = (state: ScopeStateUnion): ScopeStateUnion => {
  return modifyScopeData(state, data => ({
    ...data,
    initialized: true
  }));
};
const updateGridAsyncStateCase = {
  prepare: (newState: GridAsyncState) => {
    return {
      payload: newState
    };
  },
  reducer: (state: ScopeStateUnion, action: PayloadAction<GridAsyncState>): ScopeStateUnion => {
    return modifyScopeData(state, data => ({
      ...data,
      gridAsyncState: action.payload
    }));
  }
};
const requestScopeLockStateCase = (state: ScopeStateUnion): ScopeStateUnion => {
  return state;
};
const receivedScopeLockStateCase = {
  prepare: (newScopeLockState: boolean) => {
    return {
      payload: newScopeLockState
    };
  },
  reducer: (state: ScopeStateUnion, action: PayloadAction<boolean>) => {
    return {
      ...state,
      hasLocks: action.payload
    };
  }
};

const receivedSetEopCase = (state: ScopeStateUnion): ScopeStateUnion => {
  // TODO: some async complete here
  return state;
};

// this is to force refresh workflows without causing a
// infinite loop
const forceRefreshWorkflowsCase = (state: ScopeStateUnion): ScopeStateUnion => {
  // TODO: loading spinner
  return modifyScopeData(state, data => ({
    ...data,
    seedOptions: {},
    workflows: {},
    eopOptions: {},
    importOptions: {},
    overlayOptions: {}
  }));
};
const receivedWorkflowsCase = (
  state: ScopeStateUnion,
  action: PayloadAction<Record<number, Workflows>>
): ScopeStateUnion => {
  // abort if state isn't ready
  if (!getScopeReadyData(state)) { return state; }

  // filter and map the new incoming seeds
  const newSeedOptionsDict: Record<PlanId, SeedPlan[]> = mapValues(action.payload, (plan, planId) => {
    const realPlanId = Number(planId) as unknown as PlanId; // the lodash mapValues type forces string keys

    return plan.plans.filter(isWorkflowSeed)
      .map((pln) => {
        return planToSeedPlan(pln.plan, realPlanId);
      });
  });
  const newSeedActualsDict: Record<PlanId, SeedActuals[]> = mapValues(action.payload, (plan, planId) => {
    const realPlanId = Number(planId) as unknown as PlanId; // the lodash mapValues type forces string keys
    return plan.seedableTimes.map((t) => memberToSeedActuals(t, realPlanId));
  });

  const newImportOptionsDict: Record<PlanId, PlanMetadata[]> = mapValues(action.payload, (plan, k) => {
    return plan.plans.filter(pln => pln.tags.includes(WORKFLOW_IMPORT.value))
      .map((pln) => pln.plan);
  });
  const newOverlayOptionsDict: Record<PlanId, string[]> = mapValues(action.payload, (plan, k) => {
    return plan.overlays;
  });

  const justWorkflowPlans = mapValues(action.payload, (plan) => plan.plans);
  const seedOptions = mergeWith(newSeedOptionsDict, newSeedActualsDict, (a, b) => {
    return a.concat(b);
  });

  // TODO: stop loading spinners
  return modifyScopeData(state, data => ({
    ...data,
    seedOptions,
    workflows: justWorkflowPlans,
    importOptions: newImportOptionsDict,
    overlayOptions: newOverlayOptionsDict
  }));
};
const workflowsErrorCase = (state: ScopeStateUnion): ScopeStateUnion => {
  return modifyScopeData(state, data => ({
    ...data,
    seedOptions: {},
    workflows: {},
    eopOptions: {},
    importOption: {}
  }));
};

const receivedEopOptionsCase = (
  state: ScopeStateUnion,
  action: PayloadAction<Record<number, (SeedActuals | SeedPlan)[]>>
): ScopeStateUnion => {
  return modifyScopeData(state, data => ({
    ...data,
    eopOptions: action.payload
  }));
};

const requestRefreshGridCase = (state: ScopeStateUnion): ScopeStateUnion => {
  return modifyScopeData(state, data => ({
    ...data,
    forceRefreshGrid: true,
    gridAsyncState: GRID_REFRESHING // is this right?
  }));
};
const requestAttachCase = (state: ScopeStateUnion): ScopeStateUnion => {
  const newRevisions = [
    {
      hidden: false,
      type: 'SingleVersion' as 'SingleVersion',
      version: 'ty-review-approved'
    }, {
      against: 'ty-rp',
      type: 'VarianceVersion' as 'VarianceVersion',
      varType: 'percentage' as 'percentage',
      version: 'ty-review-approved'
    }
  ];
  if (isReady(state)) {
    return {
      ...state,
      mainConfig: {
        ...state.mainConfig,
        revisions: [...state.mainConfig.revisions, ...newRevisions]
      }
    };
  }
  return state;
};

const scopeSliceReducer = createSlice({
  name: 'scope',
  initialState: createInitScopeState(),
  reducers: {
    requestCreateScope: requestCreateScopeCase,
    requestCreateSmartScope: requestCreateSmartScopeCase,
    receivedCreateScope: receivedCreateScopeCase,
    requestScope: requestScopeCase,
    receivedScopeDynamic: receivedScopeCaseDynamic,
    receivedScope: receivedScopeCase,
    scopeNotFound: scopeNotFoundCase,
    clearScope: clearScopeCase,
    receivedSeedCurrentScope: receivedSeedCurrentScopeCase,
    updateGridAsyncState: updateGridAsyncStateCase,
    requestScopeLockState: requestScopeLockStateCase,
    receivedScopeLockState: receivedScopeLockStateCase,
    receivedSetEop: receivedSetEopCase,
    // grid async saving
    requestSeedCurrentScope: gridSaving,
    requestSetEop: gridSaving,
    requestImportVersion: gridSaving,
    requestUndoScope: gridSaving,
    requestRedoScope: gridSaving,
    // grid async refreshing
    receiveUndoScope: gridRefreshing,
    receiveRedoScope: gridRefreshing,
    requestRefreshGrid: requestRefreshGridCase,
    // grid async completed refresh
    receiveRefreshGrid: gridRefreshed,
    updatePersistenceStatus: updatePersistenceStatusCase,
    requestAttach: requestAttachCase,
    // workflow actions
    forceRefreshWorkflows: forceRefreshWorkflowsCase,
    workflowsError: workflowsErrorCase,
    // add private version, async logic handled in scope.epics
    receivedEopOptions: receivedEopOptionsCase
  },
  extraReducers: (builder) => {
    builder.addCase(requestAvailableScopes, (state, _action) => {
      return modifyScopeData(state, data => ({
        ...data,
        isFetching: true
      }));
    });
    builder.addCase(receivedAvailableScopes, (state, _action) => {
      return modifyScopeData(state, data => ({
        ...data,
        isFetching: false
      }));
    });
    // #region "fetchWorkflows"
    // these use strings due to a import order runtime error
    // that makes it so that accessing the derived .type fails to compile
    builder.addCase<
    string,
    PayloadAction<Record<number, Workflows>>
    >('scope/fetchWorkflows/fulfilled', (state, action) => {
      const scopeWithNewWorkflows = receivedWorkflowsCase(state, action);
      return scopeWithNewWorkflows;
    });
    builder.addCase('scope/fetchWorkflows/rejected', (state) => {
      return modifyScopeData(state, data => ({
        ...data,
        workflows: {}
      }));
    });
  }
});

export const {
  requestCreateScope,
  requestCreateSmartScope,
  receivedCreateScope,
  requestScope,
  receivedScope,
  receivedScopeDynamic,
  scopeNotFound,
  clearScope,
  receivedSeedCurrentScope,
  updateGridAsyncState,
  requestScopeLockState,
  receivedScopeLockState,
  requestSeedCurrentScope,
  requestSetEop,
  receivedSetEop,
  requestImportVersion,
  requestUndoScope,
  requestRedoScope,
  receiveUndoScope,
  receiveRedoScope,
  requestRefreshGrid,
  receiveRefreshGrid,
  updatePersistenceStatus,
  requestAttach,
  forceRefreshWorkflows,
  workflowsError,
  receivedEopOptions
} = scopeSliceReducer.actions;
export const { name: scopeSliceName } = scopeSliceReducer;
export default scopeSliceReducer.reducer;
