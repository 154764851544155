import { BindAll } from 'lodash-decorators';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AppState, AppThunkDispatch } from '../../store';
import browserHistory from '../../browserHistory';
import SearchBox, { SearchItem } from './SearchBox';
import UserDropdown from './UserDropdown';
import { resetScope } from 'state/scope/Scope.actions';
import { ViewEntry } from 'state/ViewConfig/ViewConfig.types';
import NavLinks from './NavLinks';
import { getScopeId } from 'state/scope/Scope.types';
import { ViewConfigSlice } from 'state/ViewConfig/ViewConfig.slice';
import { Lazy } from 'fp-ts/lib/function';
import { AuthContext } from 'services';
import BrandLogo from '../../images/S5Logo-White-NoInnovation-Centered.png';
import { QuestionIcon } from './QuestionIcon';
function mapStateToProps(state: AppState): HeaderValueProps {
  return {
    activeViews: state.viewConfigSlice.activeViews,
    scopeId: getScopeId(state.scope),
    facet: state.viewConfigSlice.facet
  };
}

export const mapDispatchToDrops = (dispatch: AppThunkDispatch) => {
  return {
    clearScope: () => {
      dispatch(resetScope());
    }
  };
};

type HeaderProps = RouteComponentProps<{}> & HeaderOwnProps & HeaderDispatchProps & HeaderValueProps;

type HeaderOwnProps = {
  logout: Lazy<void>,
  name: string | null | undefined,
  email: string | null | undefined,
  // TODO: Figure out where the picture can potentially come from
  picture: string | null | undefined
};

type HeaderValueProps = {
  scopeId: string | undefined,
  facet: ViewConfigSlice['facet'],
  activeViews?: ViewEntry[]
}

type HeaderDispatchProps = ReturnType<typeof mapDispatchToDrops>

@BindAll()
class Header extends React.Component<HeaderProps> {
  public onItemSelect(id: string) {
    const newHistoryState = { ...browserHistory.location };
    newHistoryState.pathname = id;
    browserHistory.push(newHistoryState);
  }

  public onLinkClick(e: React.MouseEvent<HTMLAnchorElement>, pathName: string) {
    e.preventDefault();
    const newHistoryState = { ...browserHistory.location };
    newHistoryState.pathname = pathName;
    browserHistory.push(newHistoryState);
  }

  public onClickLogo = () => {
    if (this.props.clearScope) {
      this.props.clearScope();
    }
  };

  public render() {
    const showNavAndSearch = this.props.activeViews &&
      this.props.scopeId &&
      !this.props.location.pathname.includes('select');
    let navLinks;
    let searchBox;

    if (showNavAndSearch && this.props.activeViews) {
      navLinks =
        <NavLinks
          activeViews={this.props.activeViews}
          pathname={this.props.location.pathname}
          scopeId={this.props.scopeId}
          facet={this.props.facet}
        />;
      searchBox = <SearchBox onItemSelect={(item: SearchItem) => this.onItemSelect(item.id)} />;
    }

    return (
      <nav className={`navbar ${!showNavAndSearch ? 'navbar-perspective' : ''}`}>
        <div className='navbar-left-container' style={{ display: 'flex' }}>
          <Link to="/" className="navbar-logo-link" onClick={this.onClickLogo} data-qa="TopNavLogoLink">
            <img className="navbar-logo" src={BrandLogo} alt="S5 Logo" data-qa="TopNavHomeIcon" />
          </Link>
          {navLinks}
        </div>
        <div className='navbar-right-container'>
          {searchBox}
          <a target="_blank" href="http://s5stratoshelp.helpdocsonline.com/home/" data-qa='TopNavHelp'>
            <QuestionIcon />
          </a>

          <UserDropdown name={this.props.name || ''} picture={this.props.picture || ''} logout={this.props.logout} />
        </div>
      </nav>
    );
  }
}

const ConnectedHeader = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToDrops
  )(Header)
);

export default function ContextHeader() {
  const value = React.useContext(AuthContext);
  if (value) {
    return <ConnectedHeader
      logout={value.logout}
      name={'UNNAMED'}
      picture={null}
      email={'UNEMAILED'}
    />;
  }
  return <React.Fragment />;
}
