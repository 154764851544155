import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RightContainerContentTypes = { type: 'comments' } | { type: 'empty' }
export interface RightContainerState {
  content: RightContainerContentTypes,
  isOpen: boolean
}
const initCommentsState: RightContainerState = {
  content: { type: 'empty' },
  isOpen: false // TODO: hydrate this from user's previous state
};

export const rightContainerSlice = createSlice({
  name: 'rightContainer',
  initialState: initCommentsState,
  reducers: {
    setRightContainerContent: (state, action: PayloadAction<RightContainerContentTypes>) => {
      state.content = action.payload;
    },
    toggleRightContainer: (state) => {
      state.isOpen = !state.isOpen;
    },
    setRightContainerOpen: (state) => {
      state.isOpen = true;
    },
    setRightContainerClosed: (state) => {
      state.isOpen = false;
    }
  }
});
export const {
  setRightContainerContent,
  toggleRightContainer,
  setRightContainerOpen,
  setRightContainerClosed
} = rightContainerSlice.actions;

export default rightContainerSlice.reducer;