import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export const supportedLanguages = [
  { code: 'en-us', name: 'English (United States)', default: true },
  { code: 'en-gb', name: 'English (United Kingdom)' }
];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    lng: navigator.language || 'en-us',
    supportedLngs: supportedLanguages.map(l => l.code),
    fallbackLng: 'en-us',
    lowerCaseLng: true, // only uses lowercase language codes
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
