import * as _ from 'lodash';
import React from 'react'; // import { CSSProperties }
import './_FloatingBackdrop.scss';

interface FloatingBackdropProps {
  numElements: number,
  maxSize?: number,
  minSize?: number,
  factor?: number,
  intervalTime?: number,
  speed?: number
}

interface FloatingBackdropState {
  elements: FloatingElement[],
  counter: number
}

interface FloatingElement {
  width: number,
  height: number,
  top: number,
  left: number,
  blur?: number,
  color?: string,
  opacity: number,
  filter?: string,
  transitionDuration?: string
}
function random(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export default class FloatingBackdrop extends React.Component<
FloatingBackdropProps,
FloatingBackdropState
> {
  protected interval: number = 500;
  protected intervalTime: number = 500;
  protected isDestroying: boolean = false;

  constructor(props: FloatingBackdropProps) {
    super(props);
    const elements: FloatingElement[] = [];
    const self = this;
    const maxSize = this.props.maxSize || 100;
    const minSize = this.props.minSize || 20;
    for (let i = 0; i < this.props.numElements; i++) {
      elements.push({
        width: random(minSize, maxSize),
        height: random(minSize, maxSize),
        top: random(-20, 120),
        left: random(-20, 120),
        blur: random(2, 3),
        opacity: random(0.4, 1)
      });
    }

    self.state = {
      elements,
      counter: 0
    };
    self.updatePositions = self.updatePositions.bind(self);
    self.intervalTime = props.intervalTime || 500;
    self.interval = window.setInterval(self.updatePositions, self.intervalTime);
  }

  public updatePositions(reset?: boolean) {
    const self = this;

    if (this.isDestroying) {
      window.clearInterval(this.interval);
      return;
    }

    let nextCounter = self.state.counter + 1;
    if (reset) {
      nextCounter = 0;
    }
    // was reset already
    if (nextCounter === self.state.counter) {
      return;
    }

    self.setState({
      counter: nextCounter,
      elements: self.state.elements.map(item => {
        const factor = self.props.factor || 15;
        const margins = 40;
        return {
          ...item,
          top: _.clamp(
            item.top + factor * random(-1, 1),
            -margins,
            100 + margins
          ),
          left: _.clamp(
            item.left + factor * random(-1, 1),
            -margins,
            100 + margins
          )
        };
      })
    });
  }

  public componentDidUpdate() {
    const self = this;
    if (self.props.intervalTime !== self.intervalTime) {
      self.intervalTime = self.props.intervalTime || 500;
      self.updatePositions(true);
      window.clearInterval(self.interval);
      self.interval = window.setInterval(
        self.updatePositions,
        self.intervalTime
      );
    }
  }

  public componentDidMount() {
    this.updatePositions();
  }
  public componentWillUnmount() {
    this.isDestroying = true;
    window.clearInterval(this.interval);
  }

  /*
  public render() {
    const items = this.state.elements.map((fItem, i) => {
      const style: CSSProperties = {
        width: `${fItem.width}px`,
        height: `${fItem.height}px`,
        top: `${fItem.top}%`,
        left: `${fItem.left}%`,
        filter: `blur(${fItem.blur}px)`,
        opacity: fItem.opacity
      };
      if (this.props.speed) {
        style.transitionDuration = `${this.props.speed}s`;
      }
      return <div key={i} className="floating-box" style={style} />;
    });
    return <div className="floating-backdrop-container">{items}</div>;
  }
  */
  public render() {
    return <div className="floating-backdrop-container" />;
  }
}
