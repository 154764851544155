import { PlanMetadata } from '../PlanMetadata';
import { Workflow } from '../Workflows';

export const workflowOrPlanToMap = <K>(
  workOrPlan: Workflow | PlanMetadata,
  mapper: (plan: PlanMetadata) => K
): K => {
  const planData = Workflow.is(workOrPlan) ? workOrPlan.plan : workOrPlan;
  return mapper(planData);
};
