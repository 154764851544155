import { createTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

const palette = createPalette({
  primary: {
    main:'#00a89b'
  }
});
const theme = createTheme({
  palette: palette,
  typography: createTypography(palette, {
    allVariants: {
      textTransform: 'none',
      color: 'rgba(0,0,0,.87)'
    },
    fontFamily: 'Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif',
    h5: {
      fontSize: '1.75rem',
      fontWeight: 'lighter',
      marginRight: '2rem',
      display:' inline-block'
    }
  }),
  props: {
    MuiPaper: {
      square: true,
      variant: 'outlined'
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: 'white'
      }
    }
  }
});
export default theme;