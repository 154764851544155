import * as iots from 'io-ts';
import { PlanMetadata } from './PlanMetadata';
import { TServerScopeMember } from '../Scope.types';

export const WORKFLOW_BALANCE = iots.literal('balanceFrom');
export const WORKFLOW_SEED = iots.literal('seedFrom');
export const WORKFLOW_IMPORT = iots.literal('importFrom');
export const WORKFLOW_TAGS = iots.union([WORKFLOW_BALANCE, WORKFLOW_SEED, WORKFLOW_IMPORT]);
export type WorkflowTags = iots.TypeOf<typeof WORKFLOW_TAGS>

export const Workflow = iots.type({
  plan: PlanMetadata,
  tags: iots.array(WORKFLOW_TAGS)
}, 'Workflow');
export type Workflow = iots.TypeOf<typeof Workflow>;
export const Workflows = iots.type({
  plans: iots.array(Workflow),
  overlays: iots.array(iots.string), /// TODO: this is wrong, replace with real type of sys-ver
  seedableTimes: iots.array(TServerScopeMember)
});
export type Workflows = iots.TypeOf<typeof Workflows>;

export const isWorkflowSeed = (work: Pick<Workflow, 'tags'>): boolean => work.tags.includes(WORKFLOW_SEED.value);
export const isWorkflowBalance = (work: Pick<Workflow, 'tags'>): boolean => work.tags.includes(WORKFLOW_BALANCE.value);
export const isWorkflowImport = (work: Pick<Workflow, 'tags'>): boolean => work.tags.includes(WORKFLOW_IMPORT.value);
