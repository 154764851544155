import * as iots from 'io-ts';

const SMARTPLAN_TOGGLE = iots.literal('toggle');
const SMARTPLAN_PERCENT = iots.literal('inputPercent');
const SMARTPLAN_SLIDER = iots.literal('slider');

const SMARTPLAN_INPUTS = iots.union([
  SMARTPLAN_TOGGLE,
  SMARTPLAN_PERCENT,
  SMARTPLAN_SLIDER
]);

export const SmartPlanTarget = iots.type({
  /* Basic text label for the input */
  text: iots.string,
  /* Popover text input for the string, HTML not allowed */
  infoText: iots.string,
  /* MetricId tied to the input */
  dataIndex: iots.string,
  /* What editor will be used for the input */
  editor: SMARTPLAN_INPUTS
}, 'SmartPlanTarget');
export type SmartPlanTarget = iots.TypeOf<typeof SmartPlanTarget>;
