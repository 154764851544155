import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { getDefaultOptionFromTime } from 'components/Reseed/ReseedPlanModal';
import { SeedInfoSelections } from 'components/Scopebar/Scopebar.types';
import { ModalBodyComponentProps } from 'components/ScopebarOption/ScopebarOptionModal';
import { DispatchGetPublishVersions } from 'components/SubmitPlan/SubmitPlan';
import { Dictionary, isEmpty, last } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import {
  DropdownProps,
  StrictDropdownItemProps
} from 'semantic-ui-react';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import { SeedActuals, SeedNull, SeedPlan } from 'state/scope/ScopeManagement.slice';
import { ReviewCorporatePlansDispatchProps } from 'views/administration/review-corporate-plans.container';

export const useHandleKeyPress = (handleSubmit: () => void) => {
  return useCallback((evt: React.KeyboardEvent<HTMLElement>) => {
    if (evt.key === 'Enter') {
      handleSubmit();
    }
  }, [handleSubmit]);
};
export const useHandleControlEnterPress = (handleSubmit: () => void) => {
  return useCallback((evt: React.KeyboardEvent<HTMLElement>) => {
    // evt.key is '\n' when coming from a multiline TextField
    // evt.metaKey = command in MacOS
    if (evt.nativeEvent.code === 'Enter' && (evt.ctrlKey || evt.metaKey)) {
      evt.preventDefault();
      handleSubmit();
    }
  }, [handleSubmit]);
};

export const useSetSelectedStringVersion = (
  versionDropdowns: StringStrictDropdownItemProps[],
  setSelectedVersion: React.Dispatch<React.SetStateAction<string | undefined>>,
  selectedVersion: string | undefined
) => {
  return useEffect(() => {
    if (versionDropdowns && !isEmpty(versionDropdowns) && !selectedVersion) {
      setSelectedVersion(versionDropdowns[0].value);
    }
  }, [versionDropdowns, setSelectedVersion, selectedVersion]);
};

export interface StringStrictDropdownItemProps extends StrictDropdownItemProps {
  value: string
}
const versionToDropdownItemProps = (version: string): StringStrictDropdownItemProps => {
  return { text: version.toLocaleUpperCase(), value: version };
};
export const usePublishVersionsToDropdowns = (publishVersions: string[] | null) => {
  return useMemo(() =>
    publishVersions ? publishVersions.map(versionToDropdownItemProps) : [],
  [publishVersions]);
};

export const useGetPublishVersions = (getPublishVersions: DispatchGetPublishVersions, currentPlanId: PlanId) => {
  return useEffect(() => {
    getPublishVersions(currentPlanId);
  }, [currentPlanId, getPublishVersions]);
};

export const useHandleChangeStringVersion = (
  setSelectedVersion: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  return useCallback((_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data && data.value && typeof data.value === 'string') {
      setSelectedVersion(data.value);
    }
  }, [setSelectedVersion]);
};
export const useHandleEscapePress = (data: ModalBodyComponentProps) => useCallback(
  (e) => {
    if (e.key === 'Escape' && data.onCancel) {
      data.onCancel();
    }
  },
  [data]
);

export const useHandleChangeStringOrNumberVersion = (
  onItemChange: (field: SeedInfoSelections, value?: string | number | undefined) => void
) => {
  return useCallback((_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const newVersion = data.value;
    if (newVersion && (typeof newVersion === 'number' || typeof newVersion === 'string')) {
      onItemChange('copyVersion', newVersion);
    }
  }, [onItemChange]);
};

export const useGetPublishVersionsOnMount = (
  getPublishVersions: ReviewCorporatePlansDispatchProps['getPublishVersions'],
  publishVersions: string[] | null,
  selectedPlanId: PlanId | null
) => {
  return useEffect(() => {
    if (!publishVersions && selectedPlanId) {
      getPublishVersions(selectedPlanId);
    }
  }, [getPublishVersions, publishVersions, selectedPlanId]);
};

export const useSetSeedFromInitialTimes = (
  seedOptions: (SeedActuals | SeedPlan)[] | undefined,
  currentSeed: SeedActuals | SeedPlan | SeedNull,
  timeVersionMap: Dictionary<(SeedActuals | SeedPlan)[]>,
  setSeed: (seed: SeedActuals | SeedPlan | SeedNull) => { payload: SeedActuals | SeedPlan | SeedNull, type: string }
) => {
  return useEffect(() => {
    // set a default time when the time members load and there isn't already a set time
    // presumes that the time keys are sort() able
    const lastTime = last(Object.keys(timeVersionMap).sort());
    if (!isEmpty(seedOptions) && !currentSeed.seedType && lastTime) {
      setSeed(getDefaultOptionFromTime(lastTime, timeVersionMap));
    }
  }, [seedOptions, currentSeed, timeVersionMap, setSeed]);
};
