import React, { createContext } from 'react';
import { Item } from './Sidebar.container';

export interface SidebarContextProps {
  children?: {},
  items?: Item[],
  pathname: string,
  pathPrefix: string,
  scope: string
}

const SidebarContext = createContext<SidebarContextProps>({
  children: {},
  items: [],
  pathname: '',
  pathPrefix: '',
  scope: ''
});

const SidebarContextProvider = (props: SidebarContextProps) => {
  const { children = {}, items = [], pathname = '', pathPrefix = '', scope = '' } = props;

  return <SidebarContext.Provider value={{ items, pathname, pathPrefix, scope }}>{children}</SidebarContext.Provider>;
};

export { SidebarContext, SidebarContextProvider };
