import React, { useContext, useEffect, useState } from 'react';
import { SidebarContext } from './SidebarContext';

interface ViewTypes {
  description?: string,
  iconClass?: string,
  isActive: boolean,
  path: string,
  title: string
}

interface SectionTypes {
  iconClass: string,
  items: ViewTypes[],
  title: string
}

const LinksSection = (props: any) => {
  const [toggled, setToggled] = useState(false);
  const { iconClass = '', items = [], title = '' }: SectionTypes = props.links;
  const { pathname = '', pathPrefix = '', scope = '' } = useContext(SidebarContext);

  // Checks to see if current pathname is in this section and toggles section if it is
  useEffect(() => {
    const activeSection = items.some(item => pathname.includes(item.path));
    if (activeSection) {
      setToggled(true);
    }
  }, [items, pathname]);

  const itemKey = title.replace(/ /g, '');

  return (
    <li className="sidebar-item" key={itemKey}>
      <button
        aria-expanded={toggled}
        className="sidebar-section-toggle"
        data-qa={itemKey}
        onClick={() => setToggled(!toggled)}
      >
        <i className={`sidebar-section-icon far ${iconClass}`} />
        <span className="sidebar-section-toggle-text">{title}</span>
      </button>
      <ul className="sidebar-section-list" hidden={!toggled}>
        {items.map(item => {
          const { title = '', iconClass = '', isActive = false, path = '', description = '' } = item;
          const tooptipId = `tooltip-${path}`;
          return (
            <li className="sidebar-link-item" key={path}>
              <a
                aria-current={isActive}
                aria-labelledby={tooptipId}
                className="sidebar-link"
                data-qa={path}
                href={`${pathPrefix}${path}${scope}`}
              >
                <i className={`sidebar-link-icon ${iconClass}`} />
                <span className="sidebar-link-text">{title}</span>
              </a>
              <p className="sidebar-tooltip" id={tooptipId} role="tooltip">
                <span className="sidebar-tooltip-text">{description}</span>
              </p>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default LinksSection;
