import { ServerScopeResponse } from 'state/scope/Scope.types';
import { mapDispatchToProps } from './TrendDetails.container';
import { ViewParams } from 'state/ViewConfig/ViewConfig.types';
import { SettingsEntry } from 'services/Settings';
import { DimensionItem } from 'components/PivotConfigurator/utils';
import { PivotCell } from 'pivot/PivotCell';

export type TrendDetailsOwnProps = {
  viewParams: ViewParams
}

export type TrendDetailsDispatchProps = typeof mapDispatchToProps;
export type TrendDetailsValueProps = TrendDetailsReady | TrendDetailsNotReady;
export type TrendDetailsReady = {
  type: 'TrendDetailsReady',
  scope: any,
  mainConfig: ServerScopeResponse,
  viewParams: ViewParams | undefined,
  forceRefreshQuickTrends: boolean,
  settings: {
    [key: string]: SettingsEntry
  }
};
export type TrendDetailsNotReady = {
  type: 'TrendDetailsNotReady',
  scope: undefined,
  mainConfig: undefined,
  viewParams: undefined,
  forceRefreshQuickTrends: undefined,
  settings: undefined
};

export type TrendDetailsProps = TrendDetailsOwnProps & TrendDetailsDispatchProps & TrendDetailsValueProps;

export interface VarianceConfig {
  type: 'Variance',
  pills: VariancePills,
  bar: VarianceBarRendererPropsWithMetrics
}

export interface VerticalStackConfig {
  type: 'VerticalStack',
  subRenderer: string,
  stacks?: string[],
  metricId?: string,
  metrics: DimensionItem,
  revisions: DimensionItem
}

export type PivotCellWithNames = PivotCell & {
  name: string,
  revision: string
};
export interface TrendDetailsItem {
  text: string,
  renderer: string,
  width?: number,
  xtype?: string,
  metrics?: DimensionItem,
  revisions?: DimensionItem,
  rendererParams?: VarianceConfig | VerticalStackConfig
}

export const isTypeVariance = (obj: VarianceConfig | VerticalStackConfig): obj is VarianceConfig => {
  return obj.type == 'Variance';
};

export const isTypeVertical = (obj: VarianceConfig | VerticalStackConfig): obj is VerticalStackConfig => {
  return obj.type == 'VerticalStack';
};
  
export interface VariancePillConfig {
  showVariance?: boolean,
  text: string
}

export interface VariancePills {
  config: VariancePillConfig[],
  metrics: DimensionItem,
  revisions: DimensionItem
}
export interface VarianceBarProps {
  value: number,
  target: number
}
export interface VarianceBarTooltipProps {
  header: string,
  value: string,
  target: string,
  renderer: string,
  dataSuffix: string
}

export interface VarianceBarRendererProps {
  tooltip: VarianceBarTooltipProps,
  bars: VarianceBarProps[]

}

export type VarianceBarRendererPropsWithMetrics = VarianceBarRendererProps & {
  metrics: DimensionItem,
  revisions: DimensionItem
}