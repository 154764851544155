import * as React from 'react';
import { connect } from 'react-redux';
import { ButtonProps, Dropdown, DropdownItemProps, DropdownProps, Segment } from 'semantic-ui-react';
import { useMemo, useRef, useState } from 'react';
import { AppState, AppThunkDispatch } from 'store';
import { getScopeReadyData } from 'state/scope/Scope.types';
import { ReseedPlanModalOwnProps } from 'components/Reseed/ReseedPlanModal';
import { useCallback, useEffect } from 'react';
import Modal from '@trendmicro/react-modal';
import { Button } from 'semantic-ui-react';
import { workflowOrPlanToMap } from 'state/scope/codecs/projections/workflowOrPlanToMap';
import { planToDropdown } from 'state/scope/codecs/projections/PlanMetadataToDropdown';
import { isEmpty, noop, head, mapValues } from 'lodash';
import { useHandleKeyPress } from 'utils/component/hooks/hooks';
import AnchorRadioSelect from 'components/AnchorRadioSelect/AnchorRadioSelect';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import { TopMembers } from 'services/Scope.client';
import { getScopeObject, planFromSpace } from 'components/Scopebar/ScopeUtils';
import { importVersion, overlayVersion } from 'state/scope/Scope.actions';
import { toast } from 'react-toastify';

export interface ImportFromVersionOwnProps {
  loading: boolean
}

const mapStateToProps = (state: AppState) => {
  const { settings } = state;
  const dimensionLabel = settings.dimensionLabelProperty;

  const readyScope = getScopeReadyData(state.scope);
  if (!readyScope) {
    return {
      importOptions: undefined,
      overlayOptions: undefined
    };
  }
  const anchor = getScopeObject(readyScope.mainConfig.memberTrees);
  return {
    initializedPlans: readyScope.mainConfig.initializedPlans,
    importOptions: readyScope.importOptions,
    overlayOptions: readyScope.overlayOptions,
    scope: readyScope,
    dimensionLabel,
    anchor,
    isMultiScope: readyScope.isMultiScope
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    handleImportVersion: (seedId: number, applyTo: PlanId) => {
      return dispatch(importVersion(seedId, applyTo));
    },
    handleOverlayVersion: (overlayId: string, applyTo: PlanId) => {
      return dispatch(overlayVersion(overlayId, applyTo));
    }
  };
};

export type ImportFromVersionProps =
  ReseedPlanModalOwnProps &
  ImportFromVersionOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ImportFromVersion = (props: ImportFromVersionProps) => {
  // TODO: may just make this an uncontrolled select, it might work better
  // TODO: pressing escape closes the dd

  const { importOptions, overlayOptions, onCancel, onSubmit, scope, initializedPlans, handleImportVersion, handleOverlayVersion, dimensionLabel, anchor, isMultiScope } = props;
  const [selectedVersion, setSelectedVersion] = useState<undefined | number | string>(undefined);
  const [ddOpen, setDdOpen] = useState(false);
  const loading = isEmpty(importOptions);
  const modalRef = useRef<HTMLDivElement | null>(document.querySelector('.titled-modal-entered'));
  const prevOptions = useRef<typeof importOptions>();
  const close = useCallback(() => ddOpen ? setDdOpen(false) : noop(), [ddOpen]);
  const [mutationPending, setMutationPending] = useState(false);

  const [
    currentImportPlanId,
    setCurrentImportPlanId
  ] = useState<PlanId | null>(head(initializedPlans) ? head(initializedPlans)!.id : null);
  const handleChangeImportSelections = useCallback((newMembers: TopMembers) => {
    if (!initializedPlans) { return; }
    setCurrentImportPlanId(planFromSpace(initializedPlans, newMembers).id);
  }, [initializedPlans, setCurrentImportPlanId]);

  useEffect(() => {
    // all of this garbage exists because the semantic dd doesn't bind the blur event on the first open
    // so we've got to manually jiggle it from the titled modal above
    // because there isn't another good way to get this click
    // TODO: fix this or replace the DD

    if (modalRef.current) {
      modalRef.current.addEventListener('click', close);
    } else {
      modalRef.current = document.querySelector('.titled-modal-entered');
    }
    return () => modalRef.current ? modalRef.current.removeEventListener('click', close) : noop();
  }, [close, ddOpen, importOptions]);
  const options: DropdownItemProps[] | undefined = useMemo(() => {
    let planOpts = !isEmpty(importOptions) && currentImportPlanId ?
      importOptions![currentImportPlanId].map((pln) => workflowOrPlanToMap(pln, planToDropdown)) :
      undefined;
    if (overlayOptions) {
      const overlayOpts = !isEmpty(overlayOptions) && currentImportPlanId ? overlayOptions![currentImportPlanId].map((opt) => {
        return {
          text: opt,
          value: opt
        };
      }) : [];
      planOpts = planOpts ? [...planOpts, ...overlayOpts] : overlayOpts;
    }
    return planOpts;
  },
    [currentImportPlanId, importOptions]);

  // if coming from undef options to new options, open the dd
  // this is why the dd needs to be controlled
  useEffect(() => {
    if (!prevOptions.current && options && !isEmpty(options) && !ddOpen) {
      setDdOpen(true);
    }
    prevOptions.current = importOptions;
  }, [importOptions, options, ddOpen]);

  const handleSubmit = useCallback(async () => {
    if (selectedVersion &&
      (typeof selectedVersion === 'number') &&
      currentImportPlanId) {
      setMutationPending(true);
      await handleImportVersion(selectedVersion, currentImportPlanId)
        .catch(() => {
          toast.error('An error occured import from version');
          throw new Error('An error occured import from version');
        }).then(() => {
          if (!isMultiScope) {
            onSubmit();// only close modal on success
          }

        }).finally(() => setMutationPending(false));
    } else if (typeof selectedVersion === 'string' && currentImportPlanId) {
      setMutationPending(true);
      await handleOverlayVersion(selectedVersion, currentImportPlanId).catch(() => {
        toast.error('An error occured import from version');
        throw new Error('An error occured import from version');
      }).then(() => {
        if (!isMultiScope) {
          onSubmit();// only close modal on success
        }
      }).finally(() => setMutationPending(false));
    }
  }, [currentImportPlanId, handleImportVersion, handleOverlayVersion, onSubmit, selectedVersion]);
  const onChange = useCallback((_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data.value && (typeof data.value === 'number' || typeof data.value === 'string')) {
      setSelectedVersion(data.value);
      // have to manually close here because the dd doesn't always close onChange
      setDdOpen(false);
    }
  }, [setSelectedVersion]);
  const handleOnSubmit = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
    handleSubmit();
  }, [handleSubmit]);
  const handleEnterPress = useHandleKeyPress(handleSubmit);

  return (
    <div className="import-version">
      <Modal.Body>
        <Segment>
          Select a version to import into your WP
        </Segment>
        <div>
          {scope && dimensionLabel && anchor ?
            <AnchorRadioSelect
              labelDimenion={dimensionLabel}
              anchor={anchor}
              onUpdateAnchorSelections={handleChangeImportSelections}
            /> :
            null}
        </div>
        <Dropdown
          data-qa="import-version-dropdown"
          loading={loading}
          fluid={true}
          search={true}
          selection={true}
          options={options}
          onChange={onChange}
          onBlur={close}
          open={ddOpen}
          onClick={() => setDdOpen(true)}
          onKeyPress={handleEnterPress}
          value={selectedVersion}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button content="Close" onClick={onCancel} />
        <Button
          content="Submit"
          className="import-version-modal-button"
          data-qa="version-import-btn-import"
          loading={loading || mutationPending}
          onClick={handleOnSubmit}
          onKeyPress={handleEnterPress}
        />
      </Modal.Footer>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportFromVersion);

