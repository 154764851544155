import { AxiosInstance } from 'axios';
import { ViewConfig } from 'state/ViewConfig/ViewConfig.types';
import { ioReportConfig, ReportConfig } from './Reports.types';
import { API_BASE_URL } from 'state/ViewConfig/ViewConfig.slice';
import { makeClientDecoder, makeErrorLog } from 'components/PivotConfigurator/utils';
import { SmartPlanPlayload } from 'state/scope/codecs/SmartPlan/SmartPlanSection';

export const CONFIG_BASE = `${API_BASE_URL}/config`;

export class ConfigService {
  protected client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public getViewConfig(): Promise<ViewConfig> {
    return this.client
      .get<ViewConfig>(`${CONFIG_BASE}/view-config`)
      .then(response => response.data);
  }

  public getReportConfig() {
    return this.client
      .get<ReportConfig>(`${CONFIG_BASE}/reports`)
      .then(response => ioReportConfig.decode(response.data));
  }

  public getSmartPlanWizardConfig() {
    return this.client
      .get<unknown>(`${CONFIG_BASE}/smartplanwizard`)
      .then(makeClientDecoder(SmartPlanPlayload))
      .then((r) => r.data)
      .catch(makeErrorLog('failed to get smartplan config'));
  }
}

export default ConfigService;
