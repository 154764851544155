import { AppState } from 'store';
import qs from 'query-string';
import { Dispatch } from 'react';
import { AxiosInstance } from 'axios';
import { loadFavorites, deleteFavorite } from 'state/favorites/favorites.slice';
import { FavoritesEntry } from 'services/Favorites';
import _ from 'lodash';
import { FacetEntry } from 'state/ViewConfig/ViewConfig.types';
import { AxiosEnv } from 'services';
import { getScopeId } from 'state/scope/Scope.types';
import { toggleSidebarExpanded } from 'state/uipose/UiPose.slice';

export type SidebarProps = SidebarValueProps & SidebarDispatchProps & SidebarOwnProps;

export interface SidebarValueProps {
  scopeId?: string | null,
  isMinimized?: boolean,
  item?: Item,
  tab?: string,
  favorites?: FavoritesEntry[],
  groupId?: string,
  pathname?: string,
  queryString?: qs.ParsedQuery,
  favoritesLoaded?: boolean,
  axios?: AxiosInstance,
  facet?: FacetEntry | undefined,
  sidebarExpanded: boolean
}

export interface SidebarDispatchProps {
  loadFavorites?: (axios: AxiosInstance) => void,
  removeFavorite?: (axios: AxiosInstance, key: string) => void,
  toggleSidebarExpanded: () => void
}

export interface SidebarOwnProps {
  tab?: string,
  serviceEnv: AxiosEnv
}

export interface Item {
  title: string,
  iconClass?: string,
  isActive?: boolean,
  isCollapsed?: boolean,
  path?: string,
  items?: Item[],
  description?: string
}

export function mapStateToProps(state: AppState, props: SidebarOwnProps): SidebarValueProps {
  let sidebarTitle = 'Loading';

  const selected = _.find(state.viewConfigSlice.activeViews, view => view.id === props.tab);
  const facet = state.viewConfigSlice.facet;

  if (facet) {
    sidebarTitle = facet.displayName;
  }

  if (!selected || !selected.views) {
    // view not loaded yet, return dummy
    return {
      item: { items: [], title: sidebarTitle },
      favorites: [],
      sidebarExpanded: state.uiPose.sidebarExpanded
    };
  }

  const newItems: Item[] = selected.views.map(
    (groups): Item => {
      return {
        title: groups.displayName,
        iconClass: groups.iconClass,
        isCollapsed: true,
        items: (groups.views || []).map(
          (item): Item => {
            const isActive = state.router.location.pathname.endsWith(`/${item.id}`);
            return {
              title: item.displayName,
              iconClass: item.iconClass,
              path: item.id,
              isActive,
              description: item.description
            };
          }
        )
      };
    }
  );
  return {
    item: {
      title: sidebarTitle,
      items: newItems
    },
    favorites: state.favorites.favorites,
    favoritesLoaded: state.favorites.loaded,
    queryString: qs.parse(state.router.location.search),
    pathname: state.router.location.pathname,
    groupId: state.viewConfigSlice.facet && state.viewConfigSlice.facet.id,
    scopeId: getScopeId(state.scope),
    facet: state.viewConfigSlice.facet,
    axios: props.serviceEnv.axios,
    sidebarExpanded: state.uiPose.sidebarExpanded
  };
}

export function mapDispatchToProps(dispatch: Dispatch<any>): SidebarDispatchProps {
  return {
    loadFavorites: (axios: AxiosInstance) => dispatch(loadFavorites(axios)),
    removeFavorite: (axios: AxiosInstance, key) => dispatch(deleteFavorite(axios, key)),
    toggleSidebarExpanded: () => dispatch(toggleSidebarExpanded())
  };
}
