import * as iots from 'io-ts';
import { ioPivotOptions } from 'components/PivotConfigurator/utils';

// eslint-disable-next-line no-useless-escape
export const ILLEGAL_XLS_SHEET_CHARACTERS = /[\\\/\*\[\]\:\,\?]/gm;

export const ioExportOptions = iots.partial({
  splitCells: iots.boolean,
  idAsHeader: iots.boolean
});
export type ExportOptions = iots.TypeOf<typeof ioExportOptions>;

// very similar to favorites items
export const ioReportItemRequired = iots.type({
  id: iots.string,
  name: iots.string,
  description: iots.string,
  type: iots.literal('report'),
  value: iots.array(ioPivotOptions)
});
export const ioReportItemOptional = iots.partial({
  loading: iots.boolean,
  paramGroup: iots.string,
  exportOptions: ioExportOptions,
  templateId: iots.string,
  pendingReports: iots.record(iots.string, iots.string) // only use in runtime
});
export const ioReportItem = iots.intersection([ioReportItemRequired, ioReportItemOptional]);
export type ReportItem = iots.TypeOf<typeof ioReportItem>;

export const ioReportCollection = iots.type({
  id: iots.string,
  name: iots.string,
  description: iots.string,
  perspective: iots.string,
  items: iots.array(ioReportItem)
});
export type ReportCollection = iots.TypeOf<typeof ioReportCollection>;

export const ioReportConfig = iots.type({
  $schema: iots.string,
  reportGroups: iots.array(ioReportCollection)
});
export type ReportConfig = iots.TypeOf<typeof ioReportConfig>;
