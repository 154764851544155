import * as iots from 'io-ts';
import type { BaseColDefParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { PlanMetadata } from './PlanMetadata';
import { isEmpty, isNil } from 'lodash';

export const UserDetails = iots.type({
  /** The auth0 uid (or magic string 'system' in the case of system owned plans) of UserDetails */
  uid: iots.string,
  /** Possibly the auth0 name of the user */
  name: iots.union([iots.string, iots.null]),
  /** Possibly the auth0 email of the user */
  email: iots.union([iots.string, iots.null])
}, 'UserDetails');
export type UserDetails = iots.TypeOf<typeof UserDetails>;

export interface PlanMetadataParams extends BaseColDefParams {
  // this is actually nullable when rowGrouping
  data: PlanMetadata
}
export const gridUserDetailsToString = (params: PlanMetadataParams): string => {
  return planMetadatToUserString('authoredBy', params);
};
export const gridUserModifiedByToString = (params: PlanMetadataParams): string => {
  return planMetadatToUserString('modifiedBy', params);
};

const planMetadatToUserString = (key: 'modifiedBy' | 'authoredBy', params: PlanMetadataParams): string => {
  // rowGroups have undef but still come through here, guard them somewhere so we don't have
  // to deal with it here
  if (!params.data) { return ''; }
  const userDetails = params.data[key];
  if ('name' in userDetails && !isNil(userDetails.name) && !isEmpty(userDetails.name)) {
    return userDetails.name;
  } else if ('email' in userDetails && !isNil(userDetails.email) && !isEmpty(userDetails.email)) {
    return userDetails.email;
  } else if ('uid' in userDetails && !isNil(userDetails.uid) && !isEmpty(userDetails.uid)) {
    return userDetails.uid;
  }
  return '';
};
