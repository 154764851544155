import { isEmpty, isEqual, isNil, keys, map, mapKeys, mapValues, pickBy, values } from 'lodash';
import { SettingsEntry } from '../../services/Settings';
import { ScopeReady, ServerScope } from '../../state/scope/Scope.types';
import { getPrimary } from 'components/PivotConfigurator/utils';
import { StrictDropdownItemProps } from 'semantic-ui-react';
import { ScopeMemberInfo, TopMembers } from 'services/Scope.client';
import { Space } from 'space';
import { PlanMetadata } from 'state/scope/codecs/PlanMetadata';
import { AnchorServerScopeMember } from 'components/AnchorRadioSelect/AnchorRadioSelect';

export function getMemberName(
  key: string,
  scopeConfig: ServerScope,
  settingsByKey: { [key: string]: SettingsEntry }
): string | undefined {
  let name: string;

  if (isNil(scopeConfig) || isNil(settingsByKey)) {
    return;
  }

  const members = scopeConfig.memberTrees;

  const level = getPrimary(scopeConfig.levels[key]).find(
    lvl => lvl.id === getPrimary(members[key])[0].v.level
  );
  name = level!.name;
  const lookupKey = `level.${name}.display`;

  if (settingsByKey) {
    const settingsEntry = settingsByKey[lookupKey];
    if (settingsEntry) {
      name = settingsEntry.value;
    }
  }
  return name;
}

export function getMemberNameFromSpace(
  key: string,
  space: Space<readonly ScopeMemberInfo[]>
): string {
  const name: string | undefined = Object.keys(space).flatMap((s) => space[s]).find((sm) => sm.id === key)?.name;

  return name || key;
}

export function getLastTimeMember(
  timePeriods: StrictDropdownItemProps[],
  rootTime?: string
): StrictDropdownItemProps[] {
  // get ly for current time
  if (isNil(rootTime) || isEmpty(timePeriods)) {
    return [];
  }

  const currentTimeMember = timePeriods.findIndex(
    year => year.value === rootTime
  );
  // go back one year
  // this depends on time being in order
  // TODO: allow seeding from other years someday
  const lastTimePeriod =
    timePeriods[Math.max(currentTimeMember - 1, 0)];

  return [lastTimePeriod];
}

export const planFromSpace = (plans: readonly PlanMetadata[], anchor: TopMembers): PlanMetadata => {
  const flatAnchor = mapValues(anchor, (v, k) => v[0]);
  const foundPlan = plans.find((pln) => isEqual(pln.space, flatAnchor));
  if (!foundPlan) { throw new Error('Plan not found in set, this shouldn\'t happen'); }
  return foundPlan;
};
export const getScopeObject = (members: ScopeReady['mainConfig']['memberTrees']): AnchorServerScopeMember =>{
  return  mapValues(members, ((tree, key) => {
    return tree[0].data.map((member) => member.v);
  }));
};

export const getMultiScopeMemberIds = (members: TopMembers) => {
  return pickBy(members, (v, k) => v.length > 1);
};
/**
 * Finds the first plan with the earliest time id
 * Note: if two plans have matching time id's this returns the first one found only
 * Use `findEarliestPlans` to find all plans matching the earliest time id in a set
 * @param  {PlanMetadata[]|readonlyPlanMetadata[]} plans
 * @returns PlanMetadata
 */
export const findEarliestPlan = (plans: PlanMetadata[] | readonly PlanMetadata[]): PlanMetadata => {
  // hope these actually alpha num sort
  const earliestTimeId = map(plans, (p) => p.space.time).sort()[0];
  const earliestPlan = plans.find((pln) => pln.space.time === earliestTimeId);
  if (!earliestPlan) throw new Error('Couldn\'t find and earliest plan, this shouldn\'t happen');
  return earliestPlan;
};
/**
 * Finds all plans that match the earliest (by alphanum search) time id
 * @param  {PlanMetadata[]|readonlyPlanMetadata[]} plans
 * @returns PlanMetadata
 */
export const findEarliestPlans = (plans: PlanMetadata[] | readonly PlanMetadata[]): PlanMetadata[] => {
  // hope these actually alpha num sort
  const earliestTimeId = findEarliestPlan(plans).space.time;
  const earliestPlans = plans.filter((pln) => pln.space.time === earliestTimeId);
  if (isEmpty(earliestPlans)) throw new Error('Couldn\'t find any earliest plans, this shouldn\'t happen');
  return earliestPlans;
};
