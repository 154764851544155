import { defaultTo, keyBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { AdvisoryStyle, AncillaryPayload, SettingsEntry } from '../../services/Settings';
import { getSettings } from 'state/settings/Settings.actions';
import jss, {Styles, Classes} from 'jss';
import { Space } from 'space';
import { ServerScopeMember } from 'state/scope/Scope.types';
import { CellAdornment } from 'components/Pivot/AgPivot.types';

export interface SettingsState {
  entries: SettingsEntry[],
  entriesByKey: {
    [key: string]: SettingsEntry
  },
  localization: {
    baseLocale: string
  },
  advisoryStyles: AdvisoryStyle[],
  advisoryClasses: Classes<string | number>,
  loading: boolean,
  dimensionLabelProperty: DimensionLabelSpace | undefined,
  cellAdornments: CellAdornment[]
}
export interface DimensionLabelSpace extends Space<keyof ServerScopeMember> { };
const initialState: SettingsState = {
  entries: [],
  entriesByKey: {},
  loading: false,
  localization: { baseLocale: 'en-US' },
  advisoryStyles: [],
  advisoryClasses: {},
  dimensionLabelProperty: undefined,
  cellAdornments: []
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSettings.pending, (state) => {
      state.entries = [];
      state.entriesByKey = {};
      state.loading = true;
    });
    builder.addCase(getSettings.fulfilled, (state, {payload}) => {
      const settingsResponse = payload;

      // combine these two since they are spread into the same key
      const newSettingsKeys = keyBy([...settingsResponse.ancillary.entries, ...settingsResponse.settings], 'key');

      // basicSettings
      state.entries = settingsResponse.settings; // don't ask my why ancillary doesn't go here
      state.entriesByKey = newSettingsKeys;
      state.loading = false;

      state.cellAdornments = defaultTo(settingsResponse.ancillary.cellAdornments, []);
      // localization
      state.localization = settingsResponse.localization;

      // advisoryStyles
      state.advisoryStyles = settingsResponse.advisoryStyles;

      // dimensionLabelProperty
      state.dimensionLabelProperty = settingsResponse.ancillary.dimensionLabelProperty;

      // WARNING: major side effects here
      // the following section magically injects <style> tags into <head> for the dynamic advisory classes
      // used by the grid
      // it creates a series of class selectors
      const classMap: Partial<Styles<string | number, any, undefined>> = {};
      settingsResponse.advisoryStyles.reduce((styles, style) => {
        styles[style.id] = {
          outline: '1px solid',
          'outline-color': style.color
        };
        return styles;
      }, classMap);

      const styles = {
        ...classMap
      };

      const { classes } = jss.createStyleSheet(styles).attach();
      state.advisoryClasses = classes;
    });
  }
});

export default settingsSlice.reducer;
