import { AxiosInstance } from 'axios';
import LoggingService from 'services/Logging.service';
import type { Comment, ProtoComment } from './Comments.types';
import { API_BASE_URL } from 'state/ViewConfig/ViewConfig.slice';
import { PerspectivePaths } from 'utils/domain/constants';
import { PlanId } from 'state/scope/codecs/PlanMetadata';

const COMMENTS_BASE = `${API_BASE_URL}` as const;

export default class CommentsService {
  protected client: AxiosInstance;
  protected logger: LoggingService;
  private logError: (logMessage:string, error: unknown) => Error;

  constructor(client: AxiosInstance) {
    this.client = client;
    this.logger = new LoggingService(this.client);
    this.logError = (logMessage: string, error: unknown) => {
      // remote logging happens here
      // bubble the error with message back up to the view layer to notify the user
      this.logger.error(logMessage, error);
      return new Error(logMessage);
    };
  }

  public addComment(planId: PlanId, newComment: ProtoComment) {
    const url = `${COMMENTS_BASE}/comments/${planId}`;

    return this.client.post(url, newComment).then((_resp) => {
      // Note: this isn't returned because it just 204's with no body
      _resp;
    }).catch((err) => {
      throw this.logError('An error occured adding your comment',err);
    });
  }

  public getPlanComments(planId: PlanId) {
    const url = `${COMMENTS_BASE}/comments/${planId}`;

    return this.client.get<Comment[]>(url).then((resp) => {
      return resp.data;
    }).catch((err) => {
      throw this.logError('An error occured adding your comment',err);
    });
  }

  public async updateComment(commentId: string) {
    // TODO: implement in backend
    const url = `${COMMENTS_BASE}/comments/${commentId}`;

    try {
      return await this.client.put(url);
    } catch (err) {
      throw this.logError('An error occured updating your comment',err);
    }
  }

  public async deleteComment(commentId: string) {
    const url = `${COMMENTS_BASE}/comments/${commentId}`;

    return this.client.delete(url).then((_resp) => {
      // Note: this isn't returned because it just 204's with no body
      _resp;
    }).catch((err) => {
      throw this.logError('An error occured deleting your comment',err);
    });
  }
}
