import { AxiosInstance, AxiosResponse } from 'axios';
import { LOCATION, PerspectivePaths, PRODLIFE, PRODUCT, TIME } from '../utils/domain/constants';
import { isValidPerspectivePath } from '../state/scope/Scope.types';
import { API_BASE_URL } from 'state/ViewConfig/ViewConfig.slice';
import * as tPromise from 'io-ts-promise';
import Scope from './Scope.client';
import LoggingService from './Logging.service';

// due to an import error in some tests, these need to be imported last, and in this order
import * as iots from 'io-ts';
import { PlanMetadata } from 'state/scope/codecs/PlanMetadata';
import qs from 'query-string';
import { reduce } from 'lodash';

const ADMIN_BASE = `${API_BASE_URL}/admin`;

interface MarkPlanLP {
  id: number,
  marker: 'lp' // May extend this someday
}

export type PlansFilters = {
  version?: string[],
  ownedBySelf?: boolean,
  [TIME]?: string[],
  [LOCATION]?: string[],
  [PRODLIFE]?: string[],
  [PRODUCT]?: string[]
}

export default class AdminService {
  protected client: AxiosInstance;
  protected logger: LoggingService;
  private logError: (error: Error) => Error;

  constructor(client: AxiosInstance) {
    this.client = client;
    this.logger = new LoggingService(this.client);
    this.logError = (error: Error) => Scope.logAsyncOrDecoderError(this.logger, error);
  }

  // TODO: add filters
  public getPlans(perspective: PerspectivePaths, filters?: PlansFilters): Promise<PlanMetadata[]> {
    if (!isValidPerspectivePath(perspective)) {
      throw new Error('An invalid perspective was accessed');
    }
    const vers = filters && filters.version ? filters.version.join() : [];
    const prods = filters?.[PRODUCT]?.join() ?? [];
    const locs = filters?.[LOCATION]?.join() ?? [];
    const _filters = filters ? { ...filters, version: vers, product: prods, location: locs } : undefined;
    return this.client
      .get(_filters ?
        `${ADMIN_BASE}/${perspective}/plans?${qs.stringify(_filters)}` :
        `${ADMIN_BASE}/${perspective}/plans}`
      )
      .then(response => tPromise.decode(iots.array(PlanMetadata), response.data));
  }

  public getMarkedPlans(perspective: PerspectivePaths) {
    if (!isValidPerspectivePath(perspective)) {
      throw new Error('An invalid perspective was accessed');
    }
    return this.client
      .get(`${ADMIN_BASE}/${perspective}/mark`)
      .then(response => tPromise.decode(iots.array(PlanMetadata), response.data))
      .catch(this.logError);
  }

  public getApprovedSubmissions(perspective: PerspectivePaths) {
    if (!isValidPerspectivePath(perspective)) {
      throw new Error('An invalid perspective was accessed');
    }
    return this.client
      .get(`${ADMIN_BASE}/${perspective}/approve`)
      .then(response => tPromise.decode(iots.array(PlanMetadata), response.data))
      .catch(this.logError);
  }

  public approvePlan(perspective: PerspectivePaths, planId: number, forceOp: boolean) {
    if (!isValidPerspectivePath(perspective)) {
      throw new Error('An invalid perspective was accessed');
    }
    const payload = JSON.stringify({
      id: planId,
      type: 'Plan',
      forceOp: forceOp
    });

    return this.client.post(
      `${ADMIN_BASE}/${perspective}/approve`,
      payload
    );
  }

  public markPlan(planId: number, perspective: PerspectivePaths) {
    const payload: MarkPlanLP = {
      id: planId,
      marker: 'lp'
    };

    return this.client
      .post(`${ADMIN_BASE}/mark`, JSON.stringify(payload))
      .then(response => response.data);
  }

  public deletePlan(planId: number, perspective: PerspectivePaths) {
    if (!isValidPerspectivePath(perspective)) {
      throw new Error('An invalid perspective was accessed');
    }
    return this.client.delete(`${ADMIN_BASE}/${perspective}/plans/${planId}`)
      .catch(this.logError);
  }
  public clonePlan(planIds: number[], version: string, perspective: PerspectivePaths) {
    if (!isValidPerspectivePath(perspective)) {
      throw new Error('An invalid perspective was accessed');
    }

    const payload = {
      planIds: planIds,
      destinationVersion: version
    };

    return this.client
      .post(`${ADMIN_BASE}/${perspective}/apply`, payload)
      .catch(this.logError);
  }
  public clonePlanActualize(planIds: number[], version: string, perspective: PerspectivePaths) {
    if (!isValidPerspectivePath(perspective)) {
      throw new Error('An invalid perspective was accessed');
    }

    // chain promises to execute sequentially
    const chain: Promise<AxiosResponse<any> | Error> = reduce(
      planIds,
      async (previousPromise, nextID) => {
        await previousPromise;

        const payload = {
          planId: nextID
        };

        return this.client
          .post(`${ADMIN_BASE}/${perspective}/actualize`, payload)
          .catch(this.logError);
      },
      Promise.resolve() as unknown as Promise<AxiosResponse<any> | Error>
    );

    return chain;
  }
}
