import viewConfigReducer from 'state/ViewConfig/ViewConfig.slice';
import macroSummariesReducer from './components/MacroSummaries/MacroSummaries.slice';
import favoritesReducer from './state/favorites/favorites.slice';
import scopeReducer from './state/scope/Scope.slice';
import settingsReducer from './state/settings/settings.slice';
import workingSetsReducer from './state/workingSets/workingSets.slice';
import chartReducer from 'components/EditableChart/EditableChart.slice';
import scopeManagementReducer from './state/scope/ScopeManagement.slice';
import nonWorkingSetsReducer from 'state/workingSets/nonWorkingSets.slice';
import commentsReducer from 'components/Comments/Comments.slice';
import rightContainerReducer from 'components/RightContainer/RightContainer.slice';
import uiPoseReducer from 'state/uipose/UiPose.slice';
import TrendSummarySlice from 'components/QuickTrends/TrendSummary/TrendSummary.slice';
import trendDetailsReducer from 'components/QuickTrends/TrendDetails/TrendDetails.slice';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  viewConfigSlice: viewConfigReducer,
  scope: scopeReducer,
  scopeManagement: scopeManagementReducer,
  settings: settingsReducer,
  favorites: favoritesReducer,
  workingSets: workingSetsReducer,
  macroSummaries: macroSummariesReducer,
  nonWorkingSets: nonWorkingSetsReducer,
  comments: commentsReducer,
  rightContainer: rightContainerReducer,
  uiPose: uiPoseReducer,
  chart: chartReducer,
  trendDetails: trendDetailsReducer,
  trendSummary: TrendSummarySlice
});
export default rootReducer;
