import React from 'react';

export const ChevronCircleLeft = () => (
  <svg
    className="icon-chevron-circle-left"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z"
    />
  </svg>
);
