import { API_BASE_URL } from 'state/ViewConfig/ViewConfig.slice';
import {
  WorkingSetReport,
  receivedWorkingSets,
  receivedReports,
  WorkingSetContextPayload,
  WorkingSetContext,
  setLastPublishTime
} from 'state/workingSets/workingSets.slice';
import { Dispatch, AnyAction } from 'redux';
import { updatePersistenceStatus } from 'state/scope/Scope.slice';
import { PersistMessage } from 'state/scope/Scope.types';
import dayjs from 'dayjs';
import { concat, defer, EMPTY, Observable, of, onErrorResumeNext } from 'rxjs';
import { catchError, concatAll, delay, repeat, retry, switchMap } from 'rxjs/operators';

const SSE_CONTEXT_TYPE = 'context-async-state';
const SSE_PERSIST_TYPE = 'context-persist-state';
const SSE_REPORT_TYPE = 'report-async-state';
const SSE_PUBLISH_TYPE = 'publish-event-status';

type MFPMessageEvent = ContextsEvent | PersistEvent | ReportEvent | PublishEvent;

interface ContextsEvent extends Event {
  type: typeof SSE_CONTEXT_TYPE,
  data: WorkingSetContextPayload[]
}
interface PersistEvent extends Event {
  type: typeof SSE_PERSIST_TYPE,
  data: PersistMessage
}
interface ReportEvent extends Event {
  type: typeof SSE_REPORT_TYPE,
  data: WorkingSetReport[]
}
interface PublishEvent extends Event {
  type: typeof SSE_PUBLISH_TYPE,
  data: { lastPublish: string }
}

// This should totally only ever be called once to create application singleton after login
// If it is called more, bad things will probably happen

export type SseMessage = { name: string, payload: any };

function eventSource(accessToken: string): Observable<Event> {

  return new Observable(observer => {
    const eventSource = new EventSource(`${API_BASE_URL}/events?token=${accessToken}`);

    function onMessage(e: Event) {
      observer.next(e);
    }
    function onError(err: Event) {
      if (err.currentTarget) {
        const es: EventSource = err.currentTarget as EventSource;
        if (es.readyState === EventSource.CLOSED) {
          observer.complete();
          eventSource.removeEventListener('message', onMessage, false);
          eventSource.removeEventListener('error', onError, false);
        } else {
          observer.error(err);
          eventSource.removeEventListener('message', onMessage, false);
          eventSource.removeEventListener('error', onError, false);
        }
      }
    }

    eventSource.addEventListener(SSE_PERSIST_TYPE, onMessage);
    eventSource.addEventListener(SSE_CONTEXT_TYPE, onMessage);
    eventSource.addEventListener(SSE_REPORT_TYPE, onMessage);
    eventSource.addEventListener(SSE_PUBLISH_TYPE, onMessage);
    eventSource.onerror = onError;

    return () => {
      eventSource.removeEventListener('message', onMessage, false);
      eventSource.removeEventListener('error', onError, false);
      eventSource.close();
    };
  });
}

export function startSse(dispatch: Dispatch<AnyAction>, accessToken$: Observable<string>) {
  function launchEventSource(token: string): Observable<Event> {
    return onErrorResumeNext(
      eventSource(token),
      defer(() =>
        of(launchEventSource(token))
          .pipe(delay(5000))
          .pipe(concatAll())
      )
    );
  }


  return accessToken$
    .pipe(switchMap(launchEventSource))
    .pipe(repeat())
    .subscribe((ev) => serverEventStreamListener(dispatch, ev));
}

const serverEventStreamListener = (dispatch: Dispatch<AnyAction>, message: Event) => {
  // TODO fix this coercison nonsense
  const msg = message as MFPMessageEvent; // This is the correct type, but the DOM lists it incorrectly
  // TODO replace the below with io-ts parsing for safety;
  const data = JSON.parse(msg.data as unknown as string) as MFPMessageEvent['data'];

  switch (msg.type) {
    case SSE_CONTEXT_TYPE: {
      const d = data as WorkingSetContext[];
      const parsedDates: WorkingSetContext[] = d.map((ws): WorkingSetContext => {
        return {
          ...ws,
          contextCreationTime: dayjs(ws.contextCreationTime)
        };
      });
      dispatch(receivedWorkingSets(parsedDates));
      break;
    }
    case SSE_PERSIST_TYPE:
      dispatch(updatePersistenceStatus(data as PersistMessage));
      break;
    case SSE_REPORT_TYPE:
      dispatch(receivedReports(data as WorkingSetReport[]));
      break;
    case SSE_PUBLISH_TYPE:
      // TODO: make this check latest time before sending
      // so we don't have to see all the events spraying through
      dispatch(setLastPublishTime((data as PublishEvent['data']).lastPublish));
      break;
    default:
      break;
  }
};
