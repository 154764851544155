import { ioPivotOptions } from 'components/PivotConfigurator/utils';
import * as iots from 'io-ts';
import { SmartPlanTarget } from './SmartPlanTarget';

const SMARTPLAN_BALANCE = iots.literal('Balance');
const SMARTPLAN_SUMMARY = iots.literal('Summary');
const SMARTPLAN_TARGETS = iots.literal('Targets');
const SMARTPLAN_GRID = iots.literal('Grid');
const SMARTPLAN_DESCRIPTION = iots.literal('Description');

const SMARTPLAN_COMPONENTS = iots.union([
  SMARTPLAN_BALANCE,
  SMARTPLAN_SUMMARY,
  SMARTPLAN_TARGETS,
  SMARTPLAN_GRID,
  SMARTPLAN_DESCRIPTION
]);

export const SmartPlanSection = iots.type({
  title: iots.string,
  icon: iots.string,
  targets: iots.array(SmartPlanTarget)
});

export const SmartPlanStep = iots.type({
  /* Section Title (not implemented yet) */
  title: iots.string,
  /* Section Description */
  description: iots.union([iots.string, iots.null]),
  /* Component Type */
  component: SMARTPLAN_COMPONENTS,
  /* Sections, leaves as empty array for non-target component */
  sections: iots.union([iots.null, iots.array(SmartPlanSection)]),
  viewParams: iots.union([ioPivotOptions, iots.null])
}, 'SmartPlanStep');
export const SmartPlanPlayload = iots.type({
  steps: iots.array(SmartPlanStep)
});
export type SmartPlanPayload = iots.TypeOf<typeof SmartPlanPlayload>;
export type SmartPlanStep = iots.TypeOf<typeof SmartPlanStep>;
export type SmartPlanSection = iots.TypeOf<typeof SmartPlanSection>;
