import { SeedPlan } from 'state/scope/ScopeManagement.slice';
import { SCOPETYPE_PLAN } from 'utils/domain/constants';
import { PlanId, PlanMetadata } from '../PlanMetadata';

export const planToSeedPlan = (pln: PlanMetadata, applyTo: PlanId): SeedPlan => {
  return {
    applyTo,
    seedType: SCOPETYPE_PLAN,
    seedTime: pln.space.time,
    name: pln.version.toLocaleUpperCase(),
    planId: pln.id
  };
};
