import React, { ComponentType, useCallback, useEffect, useRef } from 'react';
import TitledModal from 'components/Modal/TitledModal';
import { DropdownItemProps, StrictDropdownItemProps } from 'semantic-ui-react';
import { SeedInfoSelections, titledModalQaSelector } from 'components/Scopebar/Scopebar.types';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import { useHandleEscapePress } from 'utils/component/hooks/hooks';

type VisibilityProp = {
  isVisible: boolean
};

export type ScopebarOptionModalProps = {
  modalTitle: string,
  modalClassName: string,
  modalDataQa?: string | undefined,
  modalBodyComponent: ComponentType<any>,
  modalBodyComponentProps: ModalBodyComponentProps
};

export type ModalBodyComponentProps = {
  copyPeriod?: string,
  copyPeriodOptions?: StrictDropdownItemProps[],
  copyVersion?: PlanId | string,
  copyVersionOptions?: StrictDropdownItemProps[],
  loading: boolean,
  onItemChange: (field: SeedInfoSelections, value?: number | string | undefined) => void,
  onCancel?: () => void,
  onSubmit?: (id?: number) => void,
  onOpen?: () => void
}

const ScopebarOptionModal = (props: VisibilityProp & ScopebarOptionModalProps) => {
  const {
    modalTitle,
    isVisible,
    modalClassName,
    modalDataQa,
    modalBodyComponent,
    modalBodyComponentProps

  } = props;

  const ModalBody = modalBodyComponent;
  const dataQaSuffix = modalDataQa ? `-${modalDataQa}` : '';
  const dataQa = `${titledModalQaSelector}${dataQaSuffix}`;
  const getDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (getDivRef.current !== null) {
      getDivRef.current.focus();
    }
  }, [getDivRef, isVisible]);
  const handleEscapePress= useHandleEscapePress(modalBodyComponentProps);
  return (
    <div ref={getDivRef} onKeyDown={handleEscapePress} tabIndex={-1}>
      <TitledModal
        title={modalTitle}
        show={isVisible}
        className={modalClassName}
        data-qa={dataQa}
        onClose={props.modalBodyComponentProps.onCancel}
      >
        <ModalBody {...modalBodyComponentProps} />
      </TitledModal>
    </div>
  );
};

export default ScopebarOptionModal;

