import { createSlice } from '@reduxjs/toolkit';

import { ViewParams } from 'state/ViewConfig/ViewConfig.types';
export interface TrendSummaryState {
  loaded: boolean,
  forceRefreshTrendSummary: boolean
}

const initState: TrendSummaryState = {
  loaded: false,
  forceRefreshTrendSummary: false
};

const TrendSummarySlice = createSlice({
  name: 'trendSummary',
  initialState: initState,
  reducers: {
    setTrendSummaryDataStale: (state) => {
      state.forceRefreshTrendSummary = true;
      return state;
    },
    completeRefreshTrendSummary: (state) => {
      state.forceRefreshTrendSummary = false;
      return state;
    }
  }
});

export const {
  completeRefreshTrendSummary,
  setTrendSummaryDataStale
} = TrendSummarySlice.actions;
export default TrendSummarySlice.reducer;
