import {
  routerMiddleware, RouterState, connectRouter
} from 'connected-react-router';
import { configureStore, Action, DeepPartial } from '@reduxjs/toolkit';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import browserHistory from '../src/browserHistory';
import rootReducer from '../src/reducers';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from 'epics';
import { ServiceEnv } from 'services';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

export type AppState = ReturnType<typeof rootReducer> & { router: RouterState }

export type AppThunk = ThunkAction<void, AppState, { dependencies: EpicDependencies }, Action<string>>
export type AppThunkDispatch = ThunkDispatch<AppState, { dependencies: EpicDependencies }, Action<string>>;
export type AppDispatch = ReturnType<typeof createMfpStore>['dispatch'];

export type EpicDependencies = {
  serviceEnv: ServiceEnv
}

export interface ThunkApi {
  dispatch: AppDispatch,
  state: AppState,
  extra: {
    dependencies: EpicDependencies
  }
}

export function createMfpStore(serviceEnv: ServiceEnv, oldState: ReturnType<typeof rootReducer>) {
  // setup redux-observable and thunk with some dependencies so we don't have to send them everywhere
  const epicMiddleware = createEpicMiddleware<any, any, AppState, EpicDependencies>({
    dependencies: { serviceEnv }
  });
  const thunkMiddleware = thunk.withExtraArgument({
    dependencies: { serviceEnv }
  });
  const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
    key: 'persistRoot',
    storage,
    whitelist: ['uiPose'],
    // autoMergeLevel2 will shallow merge persist state and initialState from the first reducer run,
    // that way initialState isn't completely overwritten by persisted state,
    // which can cause issues when we add to the persisted state, but a user loads without the added keys
    stateReconciler: autoMergeLevel2
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    // @ts-ignore
    reducer: connectRouter(browserHistory)(persistedReducer),
    // @ts-ignore
    middleware: [routerMiddleware(browserHistory), thunkMiddleware, epicMiddleware],
    devTools: process.env.NODE_ENV !== 'production',
    // asserting here to avoid a type error with discriminated unions
    preloadedState: oldState as DeepPartial<ReturnType<typeof rootReducer>>
  });
  // persist the state here
  persistStore(store);
  epicMiddleware.run(rootEpic);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    // @ts-ignore
    module.hot.accept('./reducers', () => store.replaceReducer(persistedReducer));
  }

  return store;
}
