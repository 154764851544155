import React, { useEffect, useRef, useState } from 'react';
import { UserIcon } from './Icons';

import i18nService, { supportedLanguages } from 'services/i18n.service';
import { useTranslation } from 'react-i18next';

interface UserDropdownProps {
  name: string,
  picture: string,
  logout(): void
}

const UserDropdown = (props: UserDropdownProps) => {
  const { i18n } = useTranslation(undefined, { i18n: i18nService });
  const [userMenuToggled, setUserMenuToggled] = useState(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  // Adds reference to check for clicks outside of menu when expanded
  const menuRef = useRef<HTMLDivElement>(null);

  // The following code is to close the menu when clicking an outside element
  // 1. Listen for changes on userMenuToggled state
  // 2. If menu is toggled, add click event listener and trigger handleClick
  //   2a. If click is not inside user menu, set userMenuToggled to false
  // 3. Remove click event listener

  const handleClick = (event: any) => {
    const { target } = event;
    const ref = menuRef.current;
    const isClickInside = ref ? ref.contains(target) : true;
    if (!isClickInside) {
      // 2a
      setUserMenuToggled(false);
    }
  };

  useEffect(() => {
    // 1
    if (userMenuToggled) {
      document.addEventListener('click', handleClick);
    } // 2
    return () => document.removeEventListener('click', handleClick); // 3
  }, [userMenuToggled]);

  const { logout } = props;

  return (
    <div className="user-menu" data-qa="navbar-user-menu" ref={menuRef}>
      <button
        className="user-menu-toggle"
        data-qa="user-menu-toggle"
        onClick={() => setUserMenuToggled(!userMenuToggled)}
        type="button"
      >
        <UserIcon />
      </button>
      <ul className={`user-menu-list${userMenuToggled ? ' open' : ''}`} data-qa="navbar-user-dropdown">
        <li className="user-menu-item user-menu-item-language" data-qa="navbar-languages">
          <span>Languages</span>
          {supportedLanguages.map((lang) => {
            return languageToLanguageButton(changeLanguage, lang, i18n.language);
          })}
        </li>
        <li className="user-menu-item">
          <button
            className="user-menu-button user-menu-button-logout"
            data-qa="navbar-logout-button"
            onClick={logout} type="button"
          >
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

const languageToLanguageButton = (
  changeLanguage: (lng: string) => void,
  lang: typeof supportedLanguages[0],
  currentLanguageCode: string
) => {
  return (
    <div key={lang.code} className={lang.code === currentLanguageCode ? 'active-lang' : 'not-active'}>
      <button type="button"
        onClick={() => changeLanguage(lang.code)}
        data-qa={`lang-button-${lang.code}`}
      >
        {lang.name}
      </button>
    </div>
  );
};

export default UserDropdown;
