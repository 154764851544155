/* eslint-disable no-console */
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import * as tPromise from 'io-ts-promise';
import { API_BASE_URL } from 'state/ViewConfig/ViewConfig.slice';
import * as iots from 'io-ts';

const SEVERITY_TRACE = 'TRACE';
const SEVERITY_INFO = 'INFO';
const SEVERITY_DEBUG = 'DEBUG';
const SEVERITY_WARN = 'WARN';
const SEVERITY_ERROR = 'ERROR';

const LoggingSeverities = iots.union([
  iots.literal(SEVERITY_TRACE),
  iots.literal(SEVERITY_INFO),
  iots.literal(SEVERITY_DEBUG),
  iots.literal(SEVERITY_WARN),
  iots.literal(SEVERITY_ERROR)
]);
type LoggingSeverities = iots.TypeOf<typeof LoggingSeverities>;

const ioLoggingPayloadRequired = iots.type({
  severity: LoggingSeverities,
  message: iots.string
});
const ioLoggingPayloadOptional = iots.partial({
  payload: iots.string,
  userId: iots.string,
  sessionId: iots.string
});
const ioLoggingPayload = iots.intersection([ioLoggingPayloadRequired, ioLoggingPayloadOptional]);
type LoggingPayload = iots.TypeOf<typeof ioLoggingPayload>;

const isAxiosError = (error: object): error is AxiosError => {
  return 'isAxiosError' in  error;
};

class LoggingService {
  protected client: AxiosInstance;
  // TODO: pull the userid from the request and pass it in as a constructor param
  // to be send to the server

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  /**
 * Static function used to narrow an unknown error and attempt to output the most information possible from it
 * @constructor
 * @param {unknown} error - The error
 */
  static errorToLoggingPayload(error?: unknown): string {
    const noErrorInformationMessage = 'No additional error information';
    const unknownErrorInformationMessage = 'Unknown Error Occured';

    if (error === undefined || error === null) {
      return noErrorInformationMessage;
    }
    const errorObjectType = typeof error;
    // "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"
    switch (typeof error) {
      case 'function':
      case 'undefined':
        return noErrorInformationMessage;
      case 'bigint':
      case 'number':
      case 'boolean':
      case 'symbol':
        return error.toString();
      case 'string':
        return error;
      case 'object':
        const errorObj = error!;
        if (errorObj instanceof Error) {
          return JSON.stringify(errorObj);
        }
        if (isAxiosError(errorObj)) {
          const serverResponse = errorObj.response ? LoggingService.errorToLoggingPayload(errorObj.response) : null;
          const serverCode = errorObj.code ? errorObj.code : 'No Code Returned';
          return serverResponse ? `HTTP Error: ${serverCode} Response: ${serverResponse}` : `HTTP Error: ${serverCode}`;
        }
        if(tPromise.isDecodeError(errorObj)){
          return errorObj.name;
        }
        return unknownErrorInformationMessage;
      default:
        return unknownErrorInformationMessage;
    }
  }

  public trace(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_TRACE,
      message: message,
      payload: errorInfo
    });
  }
  public info(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_INFO,
      message: message,
      payload: errorInfo
    });
  }
  public debug(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_DEBUG,
      message: message,
      payload: errorInfo
    });
  }
  public warn(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_WARN,
      message: message,
      payload: errorInfo
    });
  }
  public error(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_ERROR,
      message: message,
      payload: errorInfo
    });
  }

  private postLogs(err: LoggingPayload) {
    // remote server takes a list of errors
    // TODO: batch errors and send them in one request
    const encodedPayload = [ioLoggingPayload.encode(err)];

    return this.client
      .post<AxiosResponse>(`${API_BASE_URL}/logs`, encodedPayload)
      .then(response => response.data);
  }
}

export default LoggingService;
