import Modal from '@trendmicro/react-modal';
import classNames from 'classnames';
import { get, isEmpty, upperFirst, noop, startCase, head } from 'lodash';
import { BindAll } from 'lodash-decorators';
import * as React from 'react';
import { connect } from 'react-redux';
import { Transition } from 'react-transition-group';
import browserHistory from '../../browserHistory';
import { TopMembers } from '../../services/Scope.client';
import {
  ServerScopeMember,
  ScopeType,
  isScopeReady,
  isScopeNotReady,
  ServerScopeResponse
} from '../../state/scope/Scope.types';
import { SCOPETYPE_ACTUALS, SCOPETYPE_PLAN, DEFAULT_DIMENSIONS } from '../../utils/domain/constants';
import FloatingBackdrop from '../FloatingBackdrop/FloatingBackdrop';
import InitializePlan from '../InitializePlan/InitializePlan';
import LoadingMask from '../LoadingMask/LoadingMask';
import ReseedPlanModal from '../Reseed/ReseedPlanModal';
import './_Scopebar.scss';
import EopToBopModal from './EopToBopModal';
import { mapDispatchToProps, mapStateToProps } from './Scopebar.container';
import { maybeGetCurrentScopeTimeId } from 'state/scope/Scope.types';
import {
  openImportQaSelector,
  openReseedQaSelector,
  openVersionQaSelector,
  openCopyQaSelector,
  ScopebarProps,
  ScopebarState,
  SeedInfoSelections,
  ScopebarOwnProps,
  ScopebarValueProps,
  ScopebarDispatch,
  openSubmitQaSelector,
  openCommentsQaSelector
} from './Scopebar.types';
import ScopeSelection from './ScopeSelection';
import ScopeText from './ScopeText';
import { getMemberName, getLastTimeMember, findEarliestPlan } from './ScopeUtils';
import { TransitionStatus } from 'react-transition-group/Transition';
import ScopebarOption from 'components/ScopebarOption/ScopebarOption';
import ScopebarOptionModal from 'components/ScopebarOption/ScopebarOptionModal';
import SaveVersion from 'components/SaveVersion/SaveVersion';
import ImportFromVersion from 'components/ImportFromVersion/ImportFromVersion';
import { AppState } from 'store';
import ScopeStatusPopover from './ScopeStatusPopover/ScopeStatusPopover';
import { getPrimary } from 'components/PivotConfigurator/utils';
import { toast } from 'react-toastify';
import SubmitPlan from 'components/SubmitPlan/SubmitPlan';
import scope from 'mockjson/scope.mock';

@BindAll()
export class Scopebar extends React.Component<ScopebarProps, ScopebarState> {
  private modalTimeout: number = 500;

  constructor(props: ScopebarProps) {
    super(props);

    this.state = {
      loading: false,
      isModalAttached: false,
      isScopeVisible: false,
      scopeError: false
    };
  }

  public getScopeText() {
    const displayText: [string | string[]] = [[]];
    const scopeConfig: ServerScopeResponse | undefined = this.props.scopeConfig;
    const labelDimension = this.props.labelDimensions!;
    if (scopeConfig && scopeConfig.scopeReady) {
      const members = scopeConfig.memberTrees;
      if (members) {
        DEFAULT_DIMENSIONS.forEach(key => {
          if (members[key]) {
            const name = getMemberName(
              key,
              scopeConfig,
              this.props.settingsByKey
            );
            if (name && labelDimension) {
              const multiScope: string[] = [];
              const getName = `${name} -`;
              getPrimary(members[key]).forEach((member) => {
                // this will set string of labelDimenion[key] as a key of the object "member.v"
                const labelType= labelDimension[key] as keyof typeof member.v;
                members[key][0].data.length !== 0 ?
                  multiScope.push(` ${member.v[labelType]}`) :
                  displayText.push(`${member.v[labelType]}`);
              });
              const text = getName + multiScope.join();
              displayText.push(text);
            }
          }
        });
      }
      if (scopeConfig.workflow) {
        displayText.push(upperFirst(scopeConfig.workflow));
      }
    } else {
      displayText.push('Please wait a moment while your scope is prepared');
    }

    if (!displayText.length) {
      displayText.push('No Scope Available');
    }

    if (this.props.facet) {
      const facetName = this.props.facet.displayName;
      displayText.push(facetName);
    }

    // this line remove the first empty array that I initial,
    //if I'm not initialize it will got the error :Source has 0 element(s) but target requires 1.
    displayText.shift();
    return displayText.join(' | ');
  }

  public componentDidMount() {
    const { scopeId, scopeConfig, updateScopeInfo } = this.props;

    if (!this.props.scopeId) {
      // no scope, so bring up the scope screen
      this.props.onOpenScope().catch(() => {
        this.setState({
          isScopeVisible: true,
          loading: false,
          scopeError: true
        });
      });
    }
    if (scopeId && !scopeConfig) {
      // if update with a scope id (IE from query param only), but no scope info in state,
      // get the info and load it into state
      updateScopeInfo(scopeId);
      this.setState({ prevScopeId: scopeId });
    }
  }

  public componentDidUpdate(prevProps: ScopebarProps, prevState: ScopebarState) {
    const {
      scopeId,
      scopeConfig,
      updateScopeInfo,
      isFetchingScope,
      scopeReady
    } = this.props;

    if (scopeId && scopeId !== this.state.prevScopeId && !scopeConfig && scopeReady) {
      // if update with a scope id (IE from query param only), but no scope info in state,
      // get the info and load it into state
      updateScopeInfo(scopeId);
      this.setState({ prevScopeId: scopeId });
    }

    if (!scopeId && !this.state.isScopeVisible && this.props.availableMembers) {
      // open scope if there's no scope id
      this.setState({
        isScopeVisible: true,
        isModalAttached: true
      });
    }

    if (!this.props.availableMembers && !isFetchingScope) {
      this.props.onOpenScope();
    }
  }

  public onAcceptScope(topMembers: TopMembers, workflow: string) {
    if (this.props.onAcceptScope) {
      this.setState(
        {
          loading: true
        },
        async () => {
          await this.props
            .onAcceptScope(topMembers, workflow)
            .then(() => {
              this.setState({
                isScopeVisible: false,
                loading: false,
                scopeError: false
              });
            })
            .catch((e) => {
              toast.error('An error has occured while accepting the scope.', {
                position: toast.POSITION.TOP_LEFT
              });
              this.setState({
                isScopeVisible: true,
                loading: false,
                scopeError: true
              });
            });
        }
      );
    } else {
      this.setState({
        isScopeVisible: false,
        loading: false
      });
    }
  }


  public onScopeClick() {
    const scopeOpen = !this.state.isScopeVisible;
    this.setState({
      isScopeVisible: scopeOpen
    });
    if (scopeOpen) {
      this.setModalVisibility(true);
    }
  }

  public setModalVisibility(isAttached: boolean) {
    this.setState({
      isModalAttached: isAttached
    });
  }

  public onModalClose() {
    if (this.props.scopeId) {
      this.setState({
        isScopeVisible: false,
        loading: false
      });
    }
  }

  public onOpenModalWithWorflows() {
    // TODO async this and loading bar the drop down or something
    this.props.requestWorkflows();
  }





  public onInitializeCancel() {
    const { clearScope } = this.props;
    if (clearScope) {
      clearScope();
    }
    const newHistoryState = { ...browserHistory.location };
    newHistoryState.pathname = '/select';
    newHistoryState.search = '';
    browserHistory.push(newHistoryState);
  }

  public render() {
    const { loading, isScopeVisible } = this.state;
    const renderModal = (state: TransitionStatus): JSX.Element => {
      const speed = this.state.loading ? 2 : 20;
      const factor = this.state.loading ? 50 : 15;
      const intervalTime = this.state.loading ? 2000 : 1000;
      const loadingClass = this.state.loading ? 'scope-loading' : '';
      let facetName = 'Loading';
      if (this.props.facet) {
        facetName = this.props.facet.displayName;
      }
      const isLoading = (!this.props.availableMembers || this.state.loading || this.props.isFetchingScope) &&
        isScopeReady(scopeConfig);
      return (
        <Modal
          onClose={this.onModalClose}
          className={classNames(
            `scope-select-modal scope-select-modal-${state}`,
            loadingClass
          )}
          show={this.state.isModalAttached}
          overlayClassName={classNames(
            `scope-overlay scope-overlay-${state}`,
            'scope-overlay-header-space',
            loadingClass
          )}
        >
          <FloatingBackdrop
            numElements={30}
            speed={speed}
            factor={factor}
            intervalTime={intervalTime}
          />
          <Modal.Body padding={false}>
            <div className="scope-select-title" style={{}}>
              <i className="far fa-crosshairs" />
              <span className="scope-select-title-text">{facetName}</span>
            </div>
            <ScopeSelection
              loading={isLoading || this.state.loading}
              labelDimension={this.props.labelDimensions!}
              availableMembers={this.props.availableMembers!}
              inSeason={this.props.inSeason}
              selectedMembers={this.props.selectedMembers}
              onAcceptScope={this.onAcceptScope}
              scopeConfig={this.props.scopeConfig}
              settingsByKey={this.props.settingsByKey}
              error={this.state.scopeError}
            />
          </Modal.Body>
          {isLoading && <LoadingMask />}
        </Modal>
      );
    };

    const {
      scopeId, scopeConfig, anyPlansUnititialized, isFetchingScope, hasEditableRevision, updateScopeInfo,
      pendingWrites
    } = this.props;


    const showInitPlan =
      scopeConfig &&
      anyPlansUnititialized &&
      !!scopeId &&
      !isScopeVisible &&
      !isFetchingScope &&
      isScopeReady(scopeConfig);

    return (
      <div className="scopebar">
        <ScopeText content={this.getScopeText()} />
        <ul className="scope-options-list">
          <ScopebarOption
            dataQa={openSubmitQaSelector}
            text={'Submit'}
            itemClass={'submit-button'}
            iconClass={'fas fa-fw fa-paper-plane'}
            isDisabled={!hasEditableRevision}
            optionModalProps={{
              modalDataQa: 'submit-plan-modal',
              modalTitle: 'Submit Plan',
              modalClassName: 'import-version-modal',
              modalBodyComponent: SubmitPlan,
              modalBodyComponentProps: {
                onItemChange: noop,
                onSubmit: noop,
                loading: loading
              }
            }}
          />
          <ScopebarOption
            dataQa={openCopyQaSelector}
            text={'Copy EOP to BOP'}
            iconClass={'far fa-fw fa-copy'}
            isDisabled={!hasEditableRevision}
            onClick={this.onOpenModalWithWorflows} // TODO: remove this when publish SSE is ready
            optionModalProps={{
              modalDataQa: undefined,
              modalTitle: 'Copy EOP to BOP',
              modalClassName: 'import-version-modal',
              modalBodyComponent: EopToBopModal,
              modalBodyComponentProps: {
                copyPeriod: undefined,
                copyVersionOptions: undefined,
                copyVersion: undefined,
                onItemChange: noop,
                onSubmit: undefined,
                loading: loading
              }
            }}
          />
          <ScopebarOption
            dataQa={openVersionQaSelector}
            text={'Save As...'}
            iconClass={'far fa-fw fa-save'}
            isDisabled={!hasEditableRevision}
            optionModalProps={{
              modalTitle: 'Save as Version',
              modalClassName: 'save-version-modal',
              modalDataQa: 'save-version-modal',
              modalBodyComponent: SaveVersion,
              modalBodyComponentProps: {
                loading: loading,
                onSubmit: noop,
                onItemChange: noop,
                onOpen: this.onOpenModalWithWorflows
              }
            }}
          />
          <ScopebarOption
            dataQa={openImportQaSelector}
            text={'Import from Version'}
            iconClass={'far fa-fw fa-file-import'}
            isDisabled={!hasEditableRevision}
            onClick={this.onOpenModalWithWorflows}
            optionModalProps={{
              modalTitle: 'Import Version from',
              modalClassName: 'import-version-modal',
              modalDataQa: 'import-version-modal',
              modalBodyComponent: ImportFromVersion,
              modalBodyComponentProps: {
                loading,
                onSubmit: undefined,
                onItemChange: noop
              }
            }}
          />
          <ScopebarOption
            dataQa={openReseedQaSelector}
            text={'Reseed'}
            iconClass={'fas fa-fw fa-sync'}
            isDisabled={!hasEditableRevision}
            onClick={this.onOpenModalWithWorflows} // TODO: remove this when publish SSE is ready
            optionModalProps={{
              modalTitle: 'Reseed Working Plan',
              modalClassName: 'import-version-modal',
              modalDataQa: 'reseed-modal',
              modalBodyComponent: ReseedPlanModal,
              modalBodyComponentProps: {
                onItemChange: noop,
                onSubmit: undefined,
                loading: loading
              }
            }}
          />
          <ScopebarOption
            dataQa={openCommentsQaSelector}
            isDisabled={!hasEditableRevision}
            text={'Comment'}
            iconClass={'fal fa-fw fa-pencil'}
            itemClass={this.props.commentsOpen ? 'open' : 'closed'}
            optionModalProps={null}
            onClick={this.props.toggleRightContainer}
          />
          {/* Scope button is not a ScopebarOption because of all the additonal logic tracked in this component */}
          <ScopeStatusPopover
            scopeId={this.props.scopeId}
            onScopeClick={this.onScopeClick}
            scopeContexts={this.props.scopeContexts}
            pendingWrites={pendingWrites}
            labelDimension={this.props.labelDimensions!}
            handleUpdateScopeInfo={(contextId, destinationPerspective) => {
              return updateScopeInfo(contextId, destinationPerspective);
            }
            }
          />
        </ul>
        <div className="scope-options">
          <Transition
            in={this.state.isScopeVisible}
            timeout={0}
            mountOnEnter={true}
            unmountOnExit={false}
            onEntering={() => {
              this.setModalVisibility(true);
              if (this.props.onOpenScope) {
                this.props.onOpenScope();
              }
              window.clearTimeout(this.modalTimeout);
            }}
            onExiting={() => {
              this.modalTimeout = window.setTimeout(() => {
                this.setModalVisibility(false);
              }, 500);
            }}
          >
            {state => renderModal(state)}
          </Transition>
          <ScopebarOptionModal
            isVisible={showInitPlan || false}
            modalTitle={'Initialize The Plan'}
            modalClassName={'initialize-plan-modal'}
            modalBodyComponent={InitializePlan}
            modalBodyComponentProps={{
              copyPeriod: undefined,
              copyVersionOptions: undefined,
              copyVersion: undefined,
              onItemChange: noop,
              onCancel: this.onInitializeCancel,
              onOpen: this.onOpenModalWithWorflows, // TODO: remove this when publish SSE is ready
              onSubmit: this.onModalClose,
              loading
            }}
          />
          {this.props.scopeId && this.props.isFetchingScope && <LoadingMask coverAll={false} />}
        </div>
      </div>
    );
  }
}

export default connect<ScopebarValueProps, ScopebarDispatch, ScopebarOwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(Scopebar);
