import Fuse from 'fuse-js-latest';
import _ from 'lodash';
import { BindAll } from 'lodash-decorators';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { ViewEntry } from 'state/ViewConfig/ViewConfig.types';

function mapStateToProps(state: AppState): SearchValueProps {
  return {
    activeViews: state.viewConfigSlice.activeViews
  };
}

interface SearchValueProps {
  activeViews?: ViewEntry[]
}

interface SearchComponentProps {
  onItemSelect?: (item: SearchItem) => void
}

type SearchProps = SearchValueProps & SearchComponentProps;

interface SearchState {
  selectedIndex: number,
  fuse: Fuse,
  results: SearchItem[],
  value: string
}

export interface SearchItem {
  id: string,
  title: string,
  type: string,
  description: string
}

@BindAll()
class SearchBox extends React.Component<SearchProps, SearchState> {
  public static convertToSearchItems(viewEntries: ViewEntry[]): SearchItem[] {
    const topLevelItems = viewEntries.map(view => ({
      id: `/${view.id}`,
      title: view.displayName,
      description: view.description || 'No Description Available',
      type: 'tab'
    }));
    const thirdLevelItems = viewEntries.map(tlView =>
      (tlView.views || []).map(slView =>
        (slView.views || []).map(blView => ({
          id: `/${tlView.id}/${blView.id}`,
          title: `${tlView.displayName} > ${blView.displayName}`,
          description: blView.description || 'No Description Available',
          type: 'subview'
        }))
      )
    );

    return [...topLevelItems, ..._.flatten(_.flatten(thirdLevelItems))];
  }

  public static getOptions(): any {
    return {
      caseSensitive: true,
      keys: ['name', 'description', 'id'],
      include: [],
      shouldSort: true,
      tokenize: true,
      verbose: false,
      distance: 100,
      threshold: 0.6,
      location: 0
    };
  }

  public static getDerivedStateFromProps(props: SearchProps, state: SearchState) {
    return {
      ...state,
      fuse: new Fuse(SearchBox.convertToSearchItems(props.activeViews || []), SearchBox.getOptions())
    };
  }
  public constructor(props: SearchProps) {
    super(props);
    this.state = {
      fuse: new Fuse(SearchBox.convertToSearchItems(props.activeViews || []), SearchBox.getOptions()),
      selectedIndex: 0,
      results: [],
      value: ''
    };
  }

  public handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const allResults: SearchItem[] = this.state.fuse.search(e.target.value);
    const maxResults = 5;
    this.setState({
      results: allResults.slice(0, maxResults - 1),
      value: e.target.value
    });
  }

  public handleKeyDown(e: React.KeyboardEvent) {
    const { results, selectedIndex } = this.state;
    if (e.keyCode === 40 && selectedIndex < results.length - 1) {
      this.setState({
        selectedIndex: selectedIndex + 1
      });
    } else if (e.keyCode === 38 && selectedIndex > 0) {
      this.setState({
        selectedIndex: selectedIndex - 1
      });
    } else if (e.keyCode === 13) {
      if (results[selectedIndex] && this.props.onItemSelect) {
        this.props.onItemSelect(results[this.state.selectedIndex]);
      }
      this.setState({
        results: [],
        selectedIndex: 0,
        value: ''
      });
    } else {
      this.setState({
        selectedIndex: 0
      });
    }
  }

  public selectItem(index: number) {
    const { results } = this.state;

    if (results[index] && this.props.onItemSelect) {
      this.props.onItemSelect(results[index]);
    }
    this.setState({
      results: [],
      selectedIndex: -1,
      value: ''
    });
  }

  public render() {
    const self = this;
    return (
      <div className="search-box">
        <div className="fuzzy-search" onKeyDown={this.handleKeyDown}>
          <input
            aria-label="Search action, views, menu items"
            autoFocus={false}
            className="search-input"
            onChange={this.handleChange}
            placeholder="Search action, views, menu items"
            type="text"
            value={this.state.value}
          />
          <div className={'search-result-holder'}>
            {self.state.results.map((val, i) => (
              <div
                key={i}
                className={['search-result-entry', `entry-type-${val.type}`].join(' ')}
                onClick={() => self.selectItem(i)}
              >
                {val.title}
                <div className={'search-description'}>{val.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SearchBox);
