import React from 'react';
import { ChevronCircleLeft } from './Icons';

interface SidebarToggleProps {
  sidebarExpanded: boolean,
  setSidebarExpanded(): void
}

const SidebarToggle = (props: SidebarToggleProps) => {
  const { sidebarExpanded, setSidebarExpanded } = props;
  return (
    <button
      aria-label={`${sidebarExpanded ? 'Collapse' : 'Expand'} sidebar`}
      className={`sidebar-toggle ${sidebarExpanded ? 'expanded' : 'collapsed'}`}
      onClick={() => setSidebarExpanded()}
      type="button"
    >
      <ChevronCircleLeft />
    </button>
  );
};

export default SidebarToggle;
