/* eslint-disable no-console */
import Axios from 'axios';
import { encode as b64enc } from 'base64-arraybuffer';
import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import * as iots from 'io-ts';
import randomstring from 'randomstring';
import { BehaviorSubject, combineLatest, defer, EMPTY, from, fromEvent, Observable, timer, using, zip, firstValueFrom } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilKeyChanged,
  filter,
  map,
  repeat,
  switchMap,
  take
} from 'rxjs/operators';

// Some common uitlities
function unwrapOrThrow<A>(type: iots.Type<A>, value: unknown): A {
  return pipe(
    type.decode(value),
    fold(
      (e) => { throw e; },
      (a) => a
    )
  );
}

export const authenticationConfig = iots.type({
  domain: iots.string,
  clientId: iots.string
});

type AuthenticationConfig = iots.TypeOf<typeof authenticationConfig>;

export const platformConfig = iots.type({
  'auth': authenticationConfig
});

const openIdConfiguration = iots.type({
  authorization_endpoint: iots.string,
  jwks_uri: iots.string,
  token_endpoint: iots.string,
  userinfo_endpoint: iots.string,
  end_session_endpoint: iots.string
});

type OpenIdConfiguration = iots.TypeOf<typeof openIdConfiguration>;

const tokenResponse = iots.type({
  id_token: iots.union([iots.null, iots.undefined, iots.string]),
  access_token: iots.string,
  refresh_token: iots.string,
  token_type: iots.literal('Bearer'),
  // standard says recommended, we'll require it
  expires_in: iots.number
});

type TokenResponse = iots.TypeOf<typeof tokenResponse>;

/* Constants used for local storage */
const STORAGE_ID_TOKEN = 'id_token';
const STORAGE_ACCESS_TOKEN = 'access_token';
const STORAGE_REFRESH_TOKEN = 'refresh_token';
const STORAGE_EXPIRATION_TIME = 'expiration_time';
const STORAGE_STATE = 'oauth2_state';
const STORAGE_VERIFIER = 'pkce_verifier';
const STORAGE_REFRESHING = 'oauth2_refreshing';
const STORAGE_EXPLICIT_LOGOUT = 'oauth2_explicit_logout';

function attemptDateParse(date: string | null) {
  if (!date) {
    return null;
  }
  const millis = Date.parse(date);
  if (isNaN(millis)) {
    console.warn(`invalid date string ${date} received.`);
    return null;
  }
  return new Date(millis);
}


/* Basic operation:
 * We have a series of subjects (because we need to modify) that are synchronized
 * with the state of localStorage (to ensure cross document token sharing).
 *
 * These subjects can be used to hook into things like request interceptors
 * to ensure that requests are held until we have a valid token.
 * Additionally, various login workflow things manipulate these subjects
 * and several un-ending streams are spawned to handle things like the auto-refresh
 * workflow.
 */
export const accessToken$: BehaviorSubject<string | null> =
  new BehaviorSubject(localStorage.getItem(STORAGE_ACCESS_TOKEN));

const refreshToken$: BehaviorSubject<string | null> =
  new BehaviorSubject(localStorage.getItem(STORAGE_REFRESH_TOKEN));

const idToken$: BehaviorSubject<string | null> =
  new BehaviorSubject(localStorage.getItem(STORAGE_ID_TOKEN));

const expirationTime$: BehaviorSubject<Date | null> =
  new BehaviorSubject(attemptDateParse(localStorage.getItem(STORAGE_EXPIRATION_TIME)));

const currentlyRefreshing$: BehaviorSubject<boolean> =
  new BehaviorSubject(localStorage.getItem(STORAGE_REFRESHING) != null);

export const explicitLogout$: BehaviorSubject<boolean> =
  new BehaviorSubject(localStorage.getItem(STORAGE_EXPLICIT_LOGOUT) != null);

export const loginError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

// Publicly visible access token
export const existingAccessToken$: Observable<string> =
  accessToken$.pipe(filter(t => t !== null)).pipe(map(t => t!));

export function existingAccessToken(): Promise<string> {
  return firstValueFrom(accessToken$
    .pipe(filter(t => t !== null))
    .pipe(map(t => t!))
    .pipe(take(1)));
}

// Global storage for integrating with non-rx code that just wants to read
let latestAccessToken: string | null = null;
accessToken$.subscribe((v) => latestAccessToken = v);

export function getLatestAccessToken(): string | null {
  return latestAccessToken;
}

/* We want to make sure that we always have the latest data authenticaiton state.
 * We hook into storage events and pipe these into the accessToken/etc
 * behavior subject to make sure we are synchronized with other tabs
 */
const storageEvent$ = fromEvent<StorageEvent>(window, 'storage');
storageEvent$
  .pipe(filter(ev => ev.key === STORAGE_ACCESS_TOKEN))
  .pipe(map(ev => ev.newValue))
  .subscribe(accessToken$);

storageEvent$
  .pipe(filter(ev => ev.key === STORAGE_ID_TOKEN))
  .pipe(map(ev => ev.newValue))
  .subscribe(idToken$);

storageEvent$
  .pipe(filter(ev => ev.key === STORAGE_REFRESH_TOKEN))
  .pipe(map(ev => ev.newValue))
  .subscribe(refreshToken$);

storageEvent$
  .pipe(filter(ev => ev.key === STORAGE_EXPIRATION_TIME))
  .pipe(map(ev => attemptDateParse(ev.newValue)))
  .subscribe(expirationTime$);

storageEvent$
  .pipe(filter(ev => ev.key === STORAGE_EXPLICIT_LOGOUT))
  .pipe(map(ev => ev.newValue != null))
  .subscribe(explicitLogout$);

storageEvent$
  .pipe(filter(ev => ev.key === STORAGE_REFRESHING))
  .pipe(map(ev => ev.newValue != null))
  .subscribe(currentlyRefreshing$);

// we also have to account for 'clear'
storageEvent$
  .pipe(filter(ev => ev.key === null))
  .subscribe((ev) => {
    accessToken$.next(null);
    idToken$.next(null);
    refreshToken$.next(null);
    expirationTime$.next(null);
    explicitLogout$.next(false);
    currentlyRefreshing$.next(false);
  });

const appJsonOverride = process &&
  process.env &&
  process.env.REACT_APP_USE_LOCAL_JSON &&
  process.env.REACT_APP_OVERRIDE_JSON &&
  `${process.env.REACT_APP_OVERRIDE_JSON}.json` || 'app.json';
const appJson = from(Axios.get(appJsonOverride))
  .pipe(map(response => response.data))
  .pipe(map(data => unwrapOrThrow(platformConfig, data)));

const authConfig = appJson
  .pipe(map(platform => platform.auth));


async function generateChallenge(verifier: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const digest = await crypto.subtle.digest('SHA-256', data);
  const b64 = b64enc(digest);
  // The internet says this is important...
  return b64.replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function setAuthStorageState(state: string) {
  sessionStorage.setItem(STORAGE_STATE, state);
}

function setVerifier(verifier: string) {
  sessionStorage.setItem(STORAGE_VERIFIER, verifier);
}

function getAuthStorageState() {
  return sessionStorage.getItem(STORAGE_STATE);
}

function getVerifier() {
  return sessionStorage.getItem(STORAGE_VERIFIER);
}

function setIdToken(token: string | null) {
  if (token) {
    localStorage.setItem(STORAGE_ID_TOKEN, token);
  } else {
    localStorage.removeItem(STORAGE_ID_TOKEN);
  }
  idToken$.next(token);
}

function setAccessToken(token: string | null) {
  if (token) {
    localStorage.setItem(STORAGE_ACCESS_TOKEN, token);
  } else {
    localStorage.removeItem(STORAGE_ACCESS_TOKEN);
  }
  accessToken$.next(token);
}

function setRefreshToken(token: string | null | undefined) {
  if (token) {
    localStorage.setItem(STORAGE_REFRESH_TOKEN, token);
  } else {
    localStorage.removeItem(STORAGE_REFRESH_TOKEN);
  }
  refreshToken$.next(token || null);
}


function setExpirationTime(time: Date | null) {
  if (time) {
    localStorage.setItem(STORAGE_EXPIRATION_TIME, time.toISOString());
  } else {
    localStorage.removeItem(STORAGE_EXPIRATION_TIME);
  }
  expirationTime$.next(time);
}

function setCurrentlyRefreshing(flag: boolean) {
  if (flag) {
    localStorage.setItem(STORAGE_REFRESHING, 'refreshing');
    currentlyRefreshing$.next(true);
  } else {
    localStorage.removeItem(STORAGE_REFRESHING);
    currentlyRefreshing$.next(false);
  }
}

function clearLoginIntermediates() {
  sessionStorage.removeItem(STORAGE_STATE);
  sessionStorage.removeItem(STORAGE_VERIFIER);
}

function generateRedirectUri(): string {
  return `${window.location.protocol}//${window.location.host}/`;
}

function generateLoginUri(authConfig: AuthenticationConfig, oidcConfig: OpenIdConfiguration,
  challenge: string, state: string) {

  const params = new URLSearchParams();
  params.set('client_id', authConfig.clientId);
  params.set('response_type', 'code');
  params.set('state', state);
  params.set('code_challenge', challenge);
  params.set('code_challenge_method', 'S256');
  params.set('redirect_uri', generateRedirectUri());
  params.set('scope', 'openid profile offline_access');
  const uri = `${oidcConfig.authorization_endpoint}?${params.toString()}`;
  return uri;
}

function getOidcConfig(ac: AuthenticationConfig) {
  return defer(() => Axios.get(`${ac.domain}/.well-known/openid-configuration`))
    .pipe(map(response => unwrapOrThrow(openIdConfiguration, response.data)));
}

const authParamsAndOidc = authConfig
  .pipe(concatMap(params =>
    getOidcConfig(params)
      .pipe(map(oidc => ({ auth: params, oidc })))
  ));


function beginLogin() {
  // First we contstruct an rxjs pipeline that will setup the requisite state we need
  const state = randomstring.generate(12);
  // PKCE challenge
  const verifier = randomstring.generate(64);
  const challenge = defer(() => from(generateChallenge(verifier)));

  return zip(authParamsAndOidc, challenge)
    .subscribe(([authAndOidc, challenge]) => {
      setAuthStorageState(state);
      setVerifier(verifier);
      const uri = generateLoginUri(authAndOidc.auth, authAndOidc.oidc, challenge, state);
      console.log(`login navigating to ${uri}`);
      location.href = uri;
    });
}

function completeLogin(code: string, state: string) {
  const storedState = getAuthStorageState();
  const verifier = getVerifier();
  // We tried, so erase them
  clearLoginIntermediates();
  if (storedState !== state) {
    console.warn('state mismatch');
    loginError$.next(true);
    return;
  }
  if (verifier === null) {
    console.warn('missing verifier');
    loginError$.next(true);
    return;
  }

  const tokenRequest = authParamsAndOidc
    .pipe(concatMap((params) => {
      const formData = new URLSearchParams();
      formData.set('client_id', params.auth.clientId);
      formData.set('code', code);
      // Verify this in the token response at some point?
      formData.set('state', state);
      formData.set('code_verifier', verifier);
      formData.set('grant_type', 'authorization_code');
      formData.set('redirect_uri', generateRedirectUri());
      return defer(() => Axios.post(params.oidc.token_endpoint, formData))
        .pipe(map(response => unwrapOrThrow(tokenResponse, response.data)));
    }));

  tokenRequest.subscribe((tokens) => {
    setAccessToken(tokens.access_token);
    setRefreshToken(tokens.refresh_token);
    setExpirationTime(new Date(Date.now() + (tokens.expires_in * 1000)));

    // We probably just navigated and this won't do anything, but in case...
    startMissingAccessTokenRefresh();
    // Send the id token to the backend
    location.href = location.pathname;
  }, (e) => {
    loginError$.next(true);
  });
}

function startMissingAccessTokenRefresh() {
  combineLatest([accessToken$, refreshToken$, explicitLogout$])
    // When accessToken is null and there is no explicit logout request
    .pipe(filter(([accessToken, refreshToken, logout]) =>
      accessToken == null && refreshToken == null && !logout))
    // We should only attempt 1 login per page load
    .pipe(take(1))
    .subscribe(() => {
      console.log('performing a login');
      beginLogin();
    });
}

export function startup() {
  clearTokenIfExpired();
  // First things first, we need to decide if we were attempting to complete a login
  const search = new URLSearchParams(location.search);
  const code = search.get('code');
  const state = search.get('state');
  // We are trying to finish a login
  if (code && state) {
    console.info('login completion parameters received');
    completeLogin(code, state);
  } else {
    // We need to decide if we are doing a fresh login
    // This should happen when there is no explicit logout and we have no accessToken
    console.log('no login completion parameters, continuing');
    startMissingAccessTokenRefresh();
  }
}

type RefreshResult = {
  idToken: string | null,
  accessToken: string | null,
  refreshToken: string | null,
  expirationTime: Date | null
};

const loggedOutResult: RefreshResult = { idToken: null, accessToken: null, refreshToken: null, expirationTime: null };
const loggedOut$: Observable<RefreshResult> = from([loggedOutResult]);

function performAutomaticRefresh(accessToken: string | null, refreshToken: string): Observable<RefreshResult> {
  console.info('performing an automatic refresh');
  return using(() => {
    setCurrentlyRefreshing(true);
    return {
      unsubscribe() {
        setCurrentlyRefreshing(false);
      }
    };
  }, () => authParamsAndOidc
    .pipe(switchMap(({ auth, oidc }) => {
      const formData = new URLSearchParams();
      formData.set('client_id', auth.clientId);
      if (accessToken) {
        formData.set('access_token', accessToken);
      }
      formData.set('grant_type', 'refresh_token');
      formData.set('refresh_token', refreshToken);
      // don't set scope, just get the same one
      return defer(() => Axios.post(oidc.token_endpoint, formData, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      }))
        .pipe(map(response => unwrapOrThrow(tokenResponse, response.data)))
        .pipe(map(token => ({
          idToken: token.id_token,
          accessToken: token.access_token,
          refreshToken: token.refresh_token,
          expirationTime: new Date(Date.now() + (token.expires_in * 1000))
        } as RefreshResult)))
        .pipe(catchError((err) => {
          // This probably indicates that our credentials are bad somehow
          // err contains the axios response here
          if (err.response && err.response.status && err.response.status < 500) {
            console.warn('token refresh was rejected');
            // this should trigger begin login elsewhere
            return loggedOut$;
          }
          console.warn('token refresh ended in error', err);
          return EMPTY;
        }));
    }))
  );
}

function receiveRefreshResult(result: RefreshResult) {
  console.info('received refresh result');
  // Access token should always be set first to prevent spurious refreshes
  // This is guarded against in missingTokenRefresh$ va distinctUntilKeyChanged but
  // will document here as well
  setAccessToken(result.accessToken);
  setRefreshToken(result.refreshToken);
  setExpirationTime(result.expirationTime);
  setIdToken(result.idToken);
}

// We need to a way of continually polling some stuff
const refreshTrigger = timer(5 * 1000).pipe(repeat());

// The 'automatic refresh' logic that happens whenever
// we find ourselves within 5 minutes of expiration
combineLatest([accessToken$, refreshToken$, expirationTime$, refreshTrigger])
  // We should not attempt to refresh if there is no refresh token
  // no expiration time, or the expiration logic is not within 5 mintues
  .pipe(filter(([_, refTok, expTime]) => {
    if (!refTok || !expTime) {
      return false;
    }
    const refreshAfter = expTime.getTime() - (5 * 60 * 1000);
    return refreshAfter < Date.now();
  }))
  .pipe(switchMap(([accessToken, refreshToken, ...rest]) =>
    currentlyRefreshing$
      .pipe(take(1))
      .pipe(concatMap((refreshing) =>
        !refreshing ? from([[accessToken, refreshToken]]) : EMPTY
      ))
  ))
  .pipe(switchMap(([accessToken, refreshToken]) =>
    performAutomaticRefresh(accessToken, refreshToken!)
  ))
  .subscribe(receiveRefreshResult, (e) => {
    console.error('timed refresh errored: ', e);
  }, () => {
    console.error('timed refresh ended');
  });

// The automatic refresh logic that happens whenever accessToken is null
// or we are past the expiration time
const missingTokenRefresh$: Observable<RefreshResult> = combineLatest([accessToken$, refreshToken$])
  // If the refresh token should happen to change before the access token
  // we want to make sure we don't immediately fire another token refresh
  .pipe(distinctUntilKeyChanged(0))
  // We have no accessToken but we do have a refresh token
  .pipe(filter(([accessToken, refreshToken]) => !accessToken && !!refreshToken))
  .pipe(switchMap(([accessToken, refreshToken]) =>
    currentlyRefreshing$
      // We would like only the current status of currentlyRefresh to dcide
      .pipe(take(1))
      .pipe(switchMap((refreshing) =>
        // If someone is already refreshing we should do nothing
        refreshing ? EMPTY : performAutomaticRefresh(accessToken, refreshToken!)
      ))
  ));

missingTokenRefresh$
  .subscribe(receiveRefreshResult, (e) => {
    console.error('missing token refresh errored: ', e);
  }, () => {
    console.error('missing token refresh ended');
  });

// Every time that we get an updated idToken we would like to
// submit it to the backend server
combineLatest([idToken$, accessToken$])
  .pipe(filter(([_, accessToken]) => !!accessToken))
  .pipe(distinctUntilKeyChanged(0))
  .subscribe(([idToken, accessToken]) => {
    Axios.post('/finplan/api/self/id', idToken, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'content-type': 'text/plain'
      }
    })
      .catch((e) => {
        console.warn('failed to submit id_token');
      });
  });

// What to do when we know that the current access token has expired?

function setExplicitLogout() {
  localStorage.setItem(STORAGE_EXPLICIT_LOGOUT, 'logout');
  explicitLogout$.next(true);
}

function clearExplicitLogout() {
  localStorage.removeItem(STORAGE_EXPLICIT_LOGOUT);
  explicitLogout$.next(false);
}

export function clearTokenIfExpired() {
  const time = attemptDateParse(localStorage.getItem(STORAGE_EXPIRATION_TIME));
  if (time && time < new Date()) {
    console.log('Cleared expired token.');
    setAccessToken(null);
  }
}

export async function logout() {
  setExplicitLogout();
  setRefreshToken(null);
  setAccessToken(null);
  setExpirationTime(null);

  // Do the rebound thing
  const { auth, oidc } = await firstValueFrom(authParamsAndOidc);
  const params = new URLSearchParams();
  params.set('client_id', auth.clientId);
  params.set('post_logout_redirect_uri', generateRedirectUri());
  location.href = `${oidc.end_session_endpoint}?${params.toString()}`;
}

export function explicitLogin() {
  clearExplicitLogout();
  // And then the automatic login should take it from here
}

export function isUnauthorized() {
  // See if we can refresh
  setAccessToken(null);
}
