import { either, isRight, right } from 'fp-ts/es6/Either';
import dayjs, { Dayjs } from 'dayjs';
import * as iots from 'io-ts';

// DayJS decoder/serializer
export const DayJsFromString = new iots.Type<Dayjs, string, unknown>(
  /** The plan's unique identifier */
  'DayJsFromString',
  (u): u is Dayjs => typeof u === 'object' && u instanceof dayjs,
  (u, c) =>
    either.chain(iots.string.validate(u, c), (s) => {
      const djs = dayjs(s);
      return djs.isValid() ? iots.success(djs) : iots.failure(u, c);
    }),
  (a) => a.toISOString()
);
