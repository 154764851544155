// dimension constants
export const TIME = 'time';
export const PRODUCT = 'product';
export const LOCATION = 'location';
export const METRICS = 'metrics';
export const REVISIONS = 'revisions';
export const PRODLIFE = 'prodlife';

// version types
export const VARIANCE_VERSION = 'VarianceVersion';
export const SINGLE_VERSION = 'SingleVersion';
export const PERCENT_TO_TOTAL_VERSION = 'PercentToTotal';
export const PERCENT_TO_GRAND_TOTAL_VERSION = 'PercentToGrandTotal';
export type VERSION_TYPES = typeof VARIANCE_VERSION |
  typeof SINGLE_VERSION |
  typeof PERCENT_TO_TOTAL_VERSION |
  typeof PERCENT_TO_GRAND_TOTAL_VERSION;
export const VERSION_TYPE_LIST =
  [VARIANCE_VERSION, SINGLE_VERSION, PERCENT_TO_TOTAL_VERSION, PERCENT_TO_GRAND_TOTAL_VERSION];


// scope type constants
export const SCOPETYPE_ACTUALS = 'actuals';
export const SCOPETYPE_PLAN = 'plan';
export const SCOPETYPE_SUBMITTED = 'submitted';
export const SCOPETYPE_APPROVED = 'approved';
export const SCOPETYPE_WORKING = 'working';

export const DEFAULT_DIMENSIONS = [PRODUCT, LOCATION, TIME] as const;

// revision constants
// TODO: move this to config or generate it
export const WP = 'wp';
export const OP = 'op';
export const RP = 'rp';
export const LP = 'lp';

// perspective constants
export const BOTTOM_UP = 'bottom_up';
export const MIDDLE_OUT = 'middle_out';
export const TOP_DOWN = 'top_down';
export const GLOBAL_DOWN = 'global_down';
export const PERSPECTIVE_PATHS = [BOTTOM_UP, MIDDLE_OUT, TOP_DOWN, GLOBAL_DOWN] as const;

export type PerspectivePaths = typeof BOTTOM_UP | typeof MIDDLE_OUT |typeof TOP_DOWN | typeof GLOBAL_DOWN;

// configurator constants
export const AVAILABLE = 'available';

// rows cols
export const ROWS = 'rows' as const;
export const COLUMNS = 'columns' as const;

// renderer
export const DEFAULT_PERCENT_FORMAT = '0.00%';
